export default {
  0: (state) => ({
    ...state,
    offers: {
      ...state.offers,
      selectorData: [],
      prodsToPublish: {},
      openedProducts: []
    },
    marketplace: {
      ...state.marketplace,
      routes: []
    }
  }),
  1: (state) => ({
    ...state,
    marketplace: {
      filters: {
        query: '',
        categoryId: '',
        species: [],
        types: [],
        variations: [],
        grades: [],
        fishingMethods: [],
        certificates: []
      }
    }
  }),
  2: (state) => ({
    ...state,
    priceRequests: {
      ...state.priceRequests,
      acceptedPriceRequest: null,
      declinedPriceRequest: null
    }
  }),
  3: (state) => ({
    ...state,
    marketplace: {
      ...state.marketplace,
      linkedSuppliers: [],
      lastFetch: {
        ...state.marketplace.lastFetch,
        linkedSuppliers: new Date()
      }
    }
  }),
  4: (state) => ({
    ...state,
    marketplace: {
      ...state.marketplace,
      filters: {
        ...state.marketplace.filters,
        favourites: false
      }
    }
  }),
  5: (state) => ({
    ...state,
    app: {
      ...state.app,
      productReferences: {}
    }
  }),
  6: (state) => ({
    ...state,
    app: {
      ...state.app,
      overlayShowing: false
    }
  }),
  7: (state) => ({
    ...state,
    marketplace: {
      ...state.marketplace,
      deliveryDateFilter: {}
    }
  }),
  8: (state) => ({
    ...state,
    modal: {
      ...state.modal,
      showToast: false,
      toastType: 'neutral',
      toastMessage: 'Welcome to Rooser',
      toastIcon: undefined,
      toastClickAction: undefined,
      toastPayload: undefined,
      toastActionLabel: '',

      toastTitle: '',
      toastPosition: 'top-right'
    }
  }),
  9: (state) => ({
    ...state,
    marketplace: {
      ...state.marketplace,
      promotedOffers: []
    }
  }),
  10: (state) => ({
    ...state,
    marketplace: { ...state.marketplace, curatedOfferIds: [] },
    onboarding: undefined
  }),
  11: (state) => ({
    ...state,
    dispatch: undefined
  }),
  12: (state) => ({
    ...state,
    marketplace: {
      ...state.marketplace,
      filters: {
        ...state.marketplace.filters,
        customCategory: ''
      }
    }
  }),
  13: (state) => ({
    ...state,
    auth: {
      ...state.auth,
      loginTokenLoading: false
    }
  }),
  14: (state) => {
    const marketplaceState = { ...state.marketplace };
    delete marketplaceState.isFilterOpen;

    return {
      ...state,
      marketplace: {
        ...marketplaceState,
        filters: {
          query: '',
          categoryId: null,
          customCategory: '',
          favourites: false
        }
      }
    };
  },
  15: (state) => {
    const marketplaceState = { ...state.marketplace };
    delete marketplaceState.isProductRequestOpen;

    return {
      ...state,
      marketplace: {
        ...marketplaceState,
        productRequest: {
          isProductRequestOpen: false,
          productRequestDeliveryDate: null
        }
      }
    };
  },
  16: (state) => ({
    ...state,
    app: {
      ...state.app,
      isSeaStore: undefined
    }
  }),
  17: (state) => ({
    ...state,
    marketplace: {
      ...state.marketplace,
      offerShareIds: []
    }
  }),
  18: (state) => ({
    ...state,
    priceRequests: {
      ...state.priceRequests,
      showPanel: true
    }
  }),
  20: (state) => ({
    ...state,
    summary: undefined
  }),
  21: (state) => ({
    ...state,
    offers: undefined
  }),
  22: (state) => ({
    ...state,
    invoices: undefined
  }),
  23: (state) => ({
    ...state,
    marketplace: {
      newOffersIds: []
    }
  }),
  24: (state) => ({
    ...state,
    marketplace: {
      ...state.marketplace,
      filters: {
        ...state.marketplace.filters,
        withSoldOut: undefined
      }
    }
  })
};
