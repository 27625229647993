export const wrappers = [];

/**
 * Abstraction layer for product analytics
 *
 */
const analytics = {
  /**
   * Initialise analytic frameworks.
   * @param {array} analyticWrappers - Initialised analytic frameworks to receive events.
   */
  init: (analyticWrappers = []) => {
    wrappers.splice(0, wrappers.length);
    if (localStorage.getItem('trackingDisabled') === '1') return;

    wrappers.push(...analyticWrappers);
  },

  /**
   * Clear any previously initialised analytic frameworks.
   */
  clear: () => {
    wrappers.splice(0, wrappers.length);
  },

  /**
   * Reset analytic frameworks.
   */
  reset: () => {
    wrappers.forEach((wrapper) => wrapper?.reset && wrapper.reset());
  },

  /**
   * Identify a user
   * @param {*} userId
   * @param {*} userTraits (for later)
   */
  identify: (userId, userTraits = null) => {
    wrappers.forEach((wrapper) => wrapper?.identify && wrapper.identify(userId, userTraits));
  },

  /**
   * Set a group for the identified user
   * @param {*} groupKey
   * @param {*} groupId
   */
  group: (groupKey, groupId) => {
    wrappers.forEach((wrapper) => wrapper?.group && wrapper.group(groupKey, groupId));
  },

  /**
   * Track an event
   * @param {*} eventName
   * @param {*} eventProps
   */
  track: (eventName, eventProps) => {
    const isAmba = localStorage.getItem('is_amba');
    wrappers.forEach(
      (wrapper) =>
        wrapper?.track &&
        wrapper.track(eventName, {
          ...eventProps,
          is_amba: isAmba === 'true' || isAmba === '1'
        })
    );
  },

  /**
   * Time an event by including the time between this call and a later 'track' call
   * @param {*} eventName
   */
  timeEvent: (eventName) => {
    wrappers.forEach((wrapper) => wrapper?.timeEvent && wrapper.timeEvent(eventName));
  },

  /**
   * Link anonymous user to a new user
   * @param {*} userId user Id
   * @param {*} prevId Anoymous user ID
   */
  alias: (userId, prevId) => {
    wrappers.forEach((wrapper) => wrapper?.alias && wrapper.alias(userId, prevId));
  },

  /**
   * Record a page view
   * @param {*} pathName
   * @param {*} isModal
   */
  page: (pathName, { isModal = false } = {}) => {
    wrappers.forEach((wrapper) => wrapper?.page && wrapper.page(pathName, { isModal }));
  },

  people: {
    /**
     * Set a user info (email, name, etc)
     */
    set: (props) => {
      wrappers.forEach((wrapper) => wrapper?.people?.set && wrapper.people.set(props));
    },
    /**
     * Increments a property of that user (e.g: videos played)
     */
    increment: (propName, inc = 1) => {
      wrappers.forEach(
        (wrapper) => wrapper?.people?.increment && wrapper.people.increment(propName, inc)
      );
    },
    /**
     * Track a financial transaction
     */
    trackCharge: (amount, props = null) => {
      wrappers.forEach(
        (wrapper) => wrapper?.people?.trackCharge && wrapper.people.trackCharge(amount, props)
      );
    }
  },

  /**
   * Event props (Google Analytics only)
   * @param {action, category, [value], [label]} eventProps
   */
  event: (eventProps) => {
    const isAmba = localStorage.getItem('is_amba');
    wrappers.forEach(
      (wrapper) =>
        wrapper?.event &&
        wrapper.event({
          ...eventProps,
          is_amba: isAmba === 'true' || isAmba === '1'
        })
    );
  },

  /**
   * Set the values of custom dimensions (Google Analytics only)
   * @param {object} setProps
   */
  set: (setProps) => {
    wrappers.forEach((wrapper) => wrapper?.set && wrapper.set(setProps));
  }
};

export default analytics;
