import React from 'react';
import { useAppDispatch } from '../../../../core/configStore';
import { checkApiHealth } from '../../actions';

export default (referrer: string): void => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    const interval = setInterval(() => {
      dispatch(checkApiHealth())
        .then(() => {
          window.location.href = referrer;
        })
        .catch(() => true);
    }, 60000);

    return () => clearInterval(interval);
  }, []);
};
