import * as ActionTypes from './actionTypes';

const initialState = {
  loading: false,
  orderIds: [],
  routes: []
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case ActionTypes.ORDERS_FETCH_ORDERS:
      return { ...state, orderIds: payload };
    case ActionTypes.ORDERS_UPDATE:
      return { ...state, orderIds: [...new Set([...state.orderIds, payload])] };
    case ActionTypes.ORDERS_REMOVE:
      return {
        ...state,
        orderIds: state.orderIds.filter((id) => id !== payload)
      };
    case ActionTypes.ORDERS_SET_LOADING:
      return { ...state, loading: payload };
    case ActionTypes.ORDERS_UPDATE_ORDER_IDS:
      return { ...state, orderIds: [...new Set(state.orderIds.concat(payload))] };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};
