import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Container, Illustration, Text } from '@rooser/reef';

import {
  buttonContainerClasses,
  containerClasses,
  goBackButtonClasses,
  illustrationContainerClasses,
  reportButtonClasses
} from './classnames';

interface ContentBoundaryProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showReport: (...args: any) => void;
}

const ContentBoundary = ({ showReport }: ContentBoundaryProps): JSX.Element => {
  const { t } = useTranslation();

  const navigateToHomepage = React.useCallback((): void => {
    document.location.href = '/';
  }, []);

  return (
    <Container>
      <div className={containerClasses}>
        <div className={illustrationContainerClasses}>
          <Illustration name="upsideDownGreyscale" size="regular" />
        </div>

        <div>
          <Text id="content-boundary-header" variant="heading2" fontWeight="regular">
            {t('app.error.header', 'Oops!')}
          </Text>
        </div>

        <div>
          <Text id="content-boundary-text" variant="text" fontWeight="regular">
            {t('app.error.errorType', 'We are experiencing a technical issue,')}
            <br />
            {t(
              'app.error.errorSolution',
              'rest assured our team is on the case and should solve it quickly.'
            )}
          </Text>
        </div>

        <div className={buttonContainerClasses}>
          <div className={goBackButtonClasses}>
            <Button
              id="content-boundary-go-back-button"
              text={t('app.error.actionBack', 'Go back')}
              variant="secondary"
              onClick={navigateToHomepage}
            />
          </div>

          <div className={reportButtonClasses}>
            <Button
              id="content-boundary-report-button"
              text={t('app.error.actionFeedback', 'Report feedback')}
              variant="primary"
              onClick={showReport}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ContentBoundary;
