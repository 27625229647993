/* eslint-disable dot-notation */
import * as Sentry from '@sentry/react';
import { applyMiddleware, createStore, compose } from 'redux';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import ReduxThunk from 'redux-thunk';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { entitiesMiddleware, errorHandlerMiddleware, trackingMiddleware } from './middlewares';
import rootReducer from './reducers';
import migrations from './migrations';

const persistConfig = {
  key: 'rooser-beta',
  storage,
  version: 8,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations, { debug: false })
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Enable "Redux DevTools" in chrome and firefox
// @todo remove dev tools hook for prod?
const composeEnhancers =
  (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) =>
    action.type === 'ENTITIES_SET' || action.type === 'persist/REHYDRATE' ? null : action,
  stateTransformer: (state) => {
    const transformedState = { ...state };
    delete transformedState.app;
    delete transformedState.entities;
    return transformedState;
  }
});

export const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(errorHandlerMiddleware, ReduxThunk, entitiesMiddleware, trackingMiddleware),
    sentryReduxEnhancer
  )
);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type ActionType = {
  entities?: any;
  payload?: any;
  type: string;
};

// Use throughout the app instead of plain `useDispatch` and `useSelector` from 'react-redux';
export const useAppDispatch = (): any => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
