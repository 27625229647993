import React from 'react';
import { LocalStorageFlagContext } from 'crypsis/LocalStorageFlagContext';
import { FlagOverridePanel } from 'crypsis/FlagOverridePanel/FlagOverridePanel';
import { useLocalStorageState } from 'crypsis/useLocalStorageState/useLocalStorageState';

export function LocalStorageFlagProvider({ children, enabled }: React.PropsWithChildren<{ enabled: boolean }>): React.JSX.Element {
  const [flags, setFlags] = useLocalStorageState('crypsis:flags', {});

  const getFlag = React.useCallback(
    (name) => flags[name]
  , [flags]);

  const setFlag = React.useCallback(
    (name, value) => setFlags({
      ...flags,
      [name]: value
    })
  , [flags, setFlags]);

  const value = React.useMemo(() => ({
    enabled,
    flags,
    getFlag,
    setFlag
  }), [enabled, flags, getFlag, setFlag]);

  return (
    <LocalStorageFlagContext.Provider value={value}>
      {children}
      {enabled && <FlagOverridePanel />}
    </LocalStorageFlagContext.Provider>
  );
}
