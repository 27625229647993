class consoleMixpanelWrapper {
  logMixpanelEvent = (message, ...args) => {
    console.info(`Mixpanel: ${message}`, ...args);
  };

  reset = () => {
    this.logMixpanelEvent('reset');
  };

  identify = (userId, userTraits) => {
    this.logMixpanelEvent('identify', userId, userTraits);
  };

  group = (groupKey, groupId) => {
    this.logMixpanelEvent('group', groupKey, groupId);
  };

  track = (eventName, eventProps) => {
    this.logMixpanelEvent('track', eventName, eventProps);
  };

  timeEvent = (eventName) => {
    this.logMixpanelEvent('timeEvent', eventName);
  };

  alias = (userId, prevId) => {
    this.logMixpanelEvent('alias', userId, prevId);
  };

  people = {
    set: (props) => {
      this.logMixpanelEvent('set', props);
    },

    increment: (propName, inc = 1) => {
      this.logMixpanelEvent('increment', propName, inc);
    },

    trackCharge: (amount, props = null) => {
      this.logMixpanelEvent('trackCharge', amount, props);
    }
  };
}

export default consoleMixpanelWrapper;
