import mixpanelLib from 'mixpanel-browser';

const API_HOST = 'https://api-eu.mixpanel.com';
/**
 * Abstraction layer for product analytics
 *
 */
class MixpanelWrapper {
  /**
   * Init Mixpanel, fake tokenw will avoid methods to throw exceptions
   * @param {*} userId
   * @param {*} userTraits (for later)
   */
  constructor(token, project) {
    mixpanelLib.init(token, { api_host: API_HOST }, project);
    this.mixpanel = mixpanelLib[project];
  }

  /**
   * Reset Mixpanel
   * @param {*} userId
   * @param {*} userTraits (for later)
   */
  reset = () => {
    this.mixpanel.reset();
    if (this.mixpanel.cookie && this.mixpanel.cookie.clear) {
      this.mixpanel.cookie.clear();
    }
  };

  /**
   * Identify a user
   * @param {*} userId
   * @param {*} userTraits (for later)
   */
  identify = (userId, userTraits) => {
    this.mixpanel.identify(userId);
    this.mixpanel.register(userTraits);
  };

  /**
   * Set a group for the identified user
   * @param {*} groupKey
   * @param {*} groupId
   */
  group = (groupKey, groupId) => {
    this.mixpanel.set_group(groupKey, groupId);
  };

  /**
   * Track an event
   * @param {*} event
   * @param {*} eventProps
   */
  track = (eventName, eventProps) => {
    this.mixpanel.track(eventName, eventProps);
  };

  /**
   * Time an event by including the time between this call and a later 'track' call
   * (Mixpanel only)
   *
   * @param {*} eventName
   */
  timeEvent = (eventName) => {
    this.mixpanel.timeEvent(eventName);
  };

  /**
   * Link anonymous user to a new user
   * @param {*} userId user Id
   * @param {*} prevId Anoymous user ID
   */
  alias = (userId, prevId) => {
    this.mixpanel.alias(userId, prevId);
  };

  people = {
    /**
     * Set a user info (email, name, etc)
     */
    set: (props) => {
      this.mixpanel.people.set(props);
    },
    /**
     * Increments a property of that user (e.g: videos played)
     */
    increment: (propName, inc = 1) => {
      this.mixpanel.people.increment(propName, inc);
    },
    /**
     * Track a financial transaction
     */
    trackCharge: (amount, props = null) => {
      this.mixpanel.people.track_charge(amount, props);
    }
  };
}

export default MixpanelWrapper;
