import { schema } from 'normalizr';

export const family = new schema.Entity('family');
export const species = new schema.Entity('species', { family });
export const type = new schema.Entity('types');
export const grade = new schema.Entity('grades');
export const variation = new schema.Entity('variations');
export const address = new schema.Entity('addresses');
export const packing = new schema.Entity('packings');
export const fishingArea = new schema.Entity('fishingAreas');
export const fishingMethod = new schema.Entity('fishingMethods');
export const invoice = new schema.Entity('invoices');
export const logisticRoute = new schema.Entity('logisticRoutes');
export const transport = new schema.Entity('transports');
export const company = new schema.Entity('companies');
export const user = new schema.Entity('users');

export const truck = new schema.Entity('trucks', { transport });
export const logisticInfo = new schema.Entity('logisticInfo', {
  truck,
  preparedBy: transport,
  preparationPlatform: address,
  arrivalPlatform: address
});

export const destination = new schema.Entity('destinations', { destinationPlatform: address });

export const product = new schema.Entity('products', {
  fishingArea,
  fishingMethod,
  grade,
  packing,
  species,
  type,
  variation
});

export const offer = new schema.Entity('offers', {
  dispatchAddress: address,
  product,
  packing,
  company
});

export const curatedOffer = new schema.Entity('curatedOffers', {
  buyerCompany: company
});

export const sale = new schema.Entity('sales', {
  buyerCompany: company,
  offer,
  packing,
  logisticInfo,
  destination
});

export const paymentReference = new schema.Entity('paymentReferences', {
  invoices: [invoice]
});

export const priceRequest = new schema.Entity('priceRequests', {
  buyerCompany: company,
  offer,
  sale
});
