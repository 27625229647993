import React from 'react';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Company, User } from '../../../core/models/models';
import { selectIsAuthenticated, selectCurrentUser } from '../../auth/selectors';
import { selectCompany } from '../../company/selectors';

export interface ErrorBoundaryProps {
  component: (props: any) => JSX.Element;
  children: React.ReactNode;
  isAuthenticated: boolean;
  user?: User;
  company?: Company;
}

export interface ErrorBoundaryState {
  error: any;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = { error: null };

  static getDerivedStateFromError(error: any): ErrorBoundaryState {
    return { error };
  }

  componentDidCatch(error: any, info: any): void {
    const { user, company, isAuthenticated } = this.props;
    const isProduction = process.env.SENTRY_ENVIRONMENT === 'production';

    // Sentry capture
    Sentry.withScope((scope) => {
      Object.keys(info).forEach((key) => {
        scope.setExtra(key, info[key]);
      });

      if (isAuthenticated && !!user && !!company) {
        scope.setUser({
          email: user.email,
          username: company && company.name,
          id: user.id.toString()
        });

        scope.setTag('language', user.language);
        scope.setTag('companyId', company && company.id);
        scope.setTag('companyType', company && company.type === 0 ? 'supplier' : 'customer');
      }

      scope.setTag('triggerOpsGenie', isProduction);

      Sentry.captureException(error);
    });
  }

  render(): React.ReactNode {
    const { error } = this.state;
    const { component: Component, children } = this.props;

    if (error && Component) {
      // render fallback UI
      return <Component error={error} showReport={() => Sentry.showReportDialog()} />;
    }

    return children;
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: selectIsAuthenticated(state),
  user: selectCurrentUser(state),
  company: selectCompany(state)
});

export default connect(mapStateToProps, null)(ErrorBoundary);
