import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

i18n
  .use(XHR)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    react: {
      useSuspense: true
    },
    load: 'languageOnly',
    fallbackLng: 'en',
    whitelist: ['en', 'fr'],
    ns: ['phrase'],
    defaultNS: 'phrase',
    backend: {
      // path where resources get loaded from, or a function
      // returning a path:
      // function(lngs, namespaces) { return customPath; }
      // the returned path will interpolate lng, ns if provided like giving a static path
      loadPath: '/i18n/{{lng}}/{{ns}}.json',

      // path to post missing resources
      addPath: 'i18n//{{lng}}/add/{{ns}}',

      // your backend server supports multiloading
      // /locales/resources.json?lng=de+en&ns=ns1+ns2
      // Adapter is needed to enable MultiLoading https://github.com/i18next/i18next-multiload-backend-adapter
      // Returned JSON structure in this case is
      // {
      //  lang : {
      //   namespaceA: {},
      //   namespaceB: {},
      //   ...etc
      //  }
      // }
      allowMultiLoading: false // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading

      // parse data after it has been fetched
      // in example use https://www.npmjs.com/package/json5
      // here it removes the letter a from the json (bad idea)
      // parse: function(data) {
      //   return data.replace(/a/g, '');
      // },

      // allow cross domain requests
      // crossDomain: false,
      // define a custom xhr function
      // can be used to support XDomainRequest in IE 8 and 9
      //
      // 'url' will be passed the value of 'loadPath'
      // 'options' will be this entire options object
      // 'callback' is a function that takes two parameters, 'data' and 'xhr'.
      //            'data' should be the key:value translation pairs for the
      //            requested language and namespace, or null in case of an error.
      //            'xhr' should be a status object, e.g. { status: 200 }
      // 'data' will be a key:value object used when saving missing translations
      // ajax: function (url, options, callback, data) {},
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;
