// import React from 'react';

/**
 * WHY DID YOU RENDER package
 * @see https://github.com/welldone-software/why-did-you-render
 */
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line
  // const whyDidYouRender = require('@welldone-software/why-did-you-render');
  // // eslint-disable-next-line
  // const ReactRedux = require('react-redux');
  // whyDidYouRender(React, {
  //   // include: [/^/],
  //   // exclude: [/^?/],
  //   // trackAllPureComponents: true,
  //   // trackExtraHooks: [
  //   //   [ReactRedux, 'useSelector'],
  //   // ],
  // });
}
