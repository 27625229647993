import { roundTo2dp } from 'core/roundings/roundings';
/**
 * Weights Converter & Calculator
 *
 * All rules, exceptions and methods related to weights and volume in Rooser
 */
class WeightLogic {
  /**
   * The minimum volume per order in Rooser;
   */
  static mininumKgPerOrder = 1;

  /**
   * List of weight units we use in Rooser
   */
  static unitsList = [
    {
      text: 'gram',
      pricedBy: 'g',
      symbol: 'g',
      value: 1
    },
    {
      text: 'kilogram',
      pricedBy: 'kg',
      symbol: 'kg',
      value: 2
    },
    {
      text: 'stone',
      pricedBy: 'st',
      symbol: 'st',
      value: 3
    },
    {
      text: 'pound',
      pricedBy: 'lb',
      symbol: 'lb',
      value: 4
    },
    {
      text: 'piece',
      pricedBy: 'kg',
      symbol: 'pc',
      value: 5
    },
    {
      text: 'piece per kg',
      pricedBy: 'kg',
      symbol: 'pc/kg',
      value: 6
    },
    {
      text: 'pot',
      pricedBy: 'pot',
      symbol: 'pot',
      value: 7
    }
  ];

  /**
   * Weight unit values in Rooser assigned to a value
   * to match in the database
   */
  static unitValues = {
    gram: 1,
    kilogram: 2,
    stone: 3,
    pound: 4,
    piece: 5,
    piecePerKilogram: 6,
    pot: 7
  };

  /**
   * The weight units converted in grams
   */
  static gradeUnitsInGrams = {
    1: 1, // gr
    2: 1000, // Kg
    3: 6350.29, // Stone
    4: 453.6, // Pounds
    5: 200, // Piece
    6: 800 // Piece per Kg
  };

  /**
   * Get the unit symbol used for display (product description, ...)
   * @param {int} unit The unit value from database
   */
  static getUnitSymbol = (unit = 2) =>
    this.unitsList[unit - 1] ? this.unitsList[unit - 1].symbol : '-';

  /**
   * Get the unit symbol used to price (e.g: pc -> priced in KG, ...)
   * @param {int} unit The unit value from database
   */
  static getPricedUnitSymbol = (unit = 2) =>
    this.unitsList[unit - 1] ? this.unitsList[unit - 1].pricedBy : '-';

  /**
   * Convert any grade min amount into grams
   * @param grade
   */
  static convertGradeMinToGrams = ({ minAmount, unit }) => minAmount * this.gradeUnitsInGrams[unit];

  /**
   * Used for sorting a list in terms of grade
   * @param {*} a first grade
   * @param {*} b next grade
   */
  static gradeComparator = (a, b) =>
    this.convertGradeMinToGrams(a) > this.convertGradeMinToGrams(b) ? -1 : 1;

  /**
   * Get the total kg per box based on the grade and packing
   * @param {*} grade
   * @param {*} packing
   */
  static getKgPerBox = (grade, packing) => {
    if (!grade || !packing) {
      return null;
    }

    const { amount: packingAmount, unit: packingUnit } = packing;
    const { minAmount, maxAmount, unit: gradeUnit } = grade;

    if (!packingAmount || !packingUnit || !minAmount || !maxAmount || !gradeUnit) {
      return null;
    }

    const avgAmount = (minAmount + maxAmount) / 2;

    if (packingUnit === this.unitValues.piece || packingUnit === this.unitValues.pot) {
      if (gradeUnit === this.unitValues.gram) {
        return parseFloat((packingAmount * avgAmount) / 1000);
      }
      return parseFloat(packingAmount * avgAmount);
    }

    return parseFloat(packingAmount);
  };

  /**
   * Get the total volume of a sale, order or offer based on grade, packing and quantity
   * @param {*} grade
   * @param {*} packing
   * @param {*} quantity
   */
  static getTotalKg = (grade, packing, quantity) => {
    if (!grade || !packing || !quantity) {
      return null;
    }

    const { amount: packingAmount, unit: packingUnit } = packing;
    const { minAmount, maxAmount, unit: gradeUnit } = grade;

    if (!packingAmount || !packingUnit || !minAmount || !maxAmount || !gradeUnit) {
      return null;
    }

    return roundTo2dp(parseFloat(quantity * this.getKgPerBox(grade, packing)));
  };

  /**
   * Checks if an offer is using pot unit
   * @param offer the offer to be checked
   */
  static isOfferUsingPot = (offer) =>
    offer && offer.packing && offer.packing.unit === this.unitValues.pot;

  /**
   * Converts price from per kg into per pot
   * Takes the grade of the offer (which uses pot as packing)
   * Assumming 1 pot contains 1 grade unit
   *
   * @param price the price in cost per kg
   * @param grade the grade of the product
   */
  static getPricePerPot = (price, grade) => {
    const { minAmount, maxAmount, unit } = grade;
    // convert grade amounts into grams
    const min = this.gradeUnitsInGrams[unit] * minAmount;
    const max = this.gradeUnitsInGrams[unit] * maxAmount;
    const avgGradeInGrams = (min + max) / 2;
    // get price per grade unit (pot)
    return parseFloat((price * avgGradeInGrams) / 1000);
  };

  /**
   * Converts price from per pot into per kg
   * Takes the grade of the offer (which uses pot as packing)
   * Assumming 1 pot contains 1 grade unit
   *
   * @param price the price in cost per pot (or cost per grade unit)
   * @param grade the grade of the product using pot packing
   */
  static getPricePerKg = (price, grade) => {
    const { minAmount, maxAmount, unit } = grade;
    // convert grade amounts into grams
    const min = this.gradeUnitsInGrams[unit] * minAmount;
    const max = this.gradeUnitsInGrams[unit] * maxAmount;
    const avgGradeInGrams = (min + max) / 2;
    // get price per kg
    return parseFloat((price * 1000) / avgGradeInGrams);
  };

  /**
   * Checks the price if it uses pot packing
   * and converts the price if needed;
   *
   * @param {*} price the price
   * @param {*} offer the offer
   */
  static getPriceToDisplay = (price, offer) => {
    const checkedPrice = this.isOfferUsingPot(offer)
      ? this.getPricePerPot(price, offer.product.grade)
      : price;
    return Math.round(checkedPrice * 100) / 100;
  };

  /**
   * Checks if the price is in per kg
   * before it can be used for price conversions
   * (ex: converting customer price to supplier price);
   *
   * @param {*} price the price
   * @param {*} offer the offer
   */
  static getPriceForCalculation = (price, offer) => {
    const checkedPrice = this.isOfferUsingPot(offer)
      ? this.getPricePerKg(price, offer.product.grade)
      : price;
    return Math.round(checkedPrice * 100) / 100;
  };
}

export default WeightLogic;
