import * as React from 'react';

interface AnalyticsPeople {
  set: (props: any) => void;
  increment: (propName: string, inc: number) => void;
  trackCharge: (amount: number, props: any) => void;
}

interface AnalyticWrapper {
  reset: () => void;
  identify: (userId: number, userTraits: any) => void;
  group: (groupKey: any, groupId: any) => void;
  track: (eventName: string, eventProps: any) => void;
  timeEvent: (eventName: string) => void;
  alias: (userId: number, prevId: number) => void;
  page: (pathName: string, { isModal }: { isModal: boolean }) => void;
  event: (eventProps: any) => void; // TODO: move this GA-specific function into generic function.
  set: (setProps: any) => void; // TODO: move this GA-specific function into generic function.
  people: AnalyticsPeople;
}

export interface Analytics extends AnalyticWrapper {
  init: (analyticWrappers: AnalyticWrapper[]) => void;
  clear: () => void;
}

interface AnalyticsContextProps {
  analytics: Analytics;
}

const AnalyticsContext = React.createContext<AnalyticsContextProps | undefined>(undefined);

export { AnalyticsContext, AnalyticsContextProps, AnalyticWrapper };
