import React from 'react';

export type GetStorageItem = (key: string) => string | null;

export type SetStorageItem = (key: string, value: string) => void;

export type AddEventListener = <K extends keyof WindowEventMap>(type: K, listener: (this: Window, ev: WindowEventMap[K]) => any, options?: boolean | AddEventListenerOptions) =>  void;

export type RemoveEventListener = <K extends keyof WindowEventMap>(type: K, listener: (this: Window, ev: WindowEventMap[K]) => any, options?: boolean | EventListenerOptions) => void;

export type WindowFns = {
  getLocalStorageItem: GetStorageItem,
  setLocalStorageItem: SetStorageItem,
  addEventListener: AddEventListener,
  removeEventListener: RemoveEventListener
};

function initialLocalStorageState<S>(key: string, getLocalStorageItem: GetStorageItem, initialState?: S | (() => S)) {
  const storedState = getLocalStorageItem(key);

  if (storedState !== null) {
    return JSON.parse(storedState);
  }

  return initialState;
}

export function useLocalStorageState<S>(key: string, initialState: S | (() => S)): [S, React.Dispatch<React.SetStateAction<S>>];

export function useLocalStorageState<S = undefined>(key: string): [S | undefined, React.Dispatch<React.SetStateAction<S | undefined>>];

export function useLocalStorageState<S>(key: string, initialState: S | (() => S), fns?: WindowFns): [S, React.Dispatch<React.SetStateAction<S>>];

export function useLocalStorageState<S>(key: string, initialState?: S | (() => S), {
  getLocalStorageItem,
  setLocalStorageItem,
  addEventListener,
  removeEventListener
}: WindowFns = {
  getLocalStorageItem: (key: string) => window.localStorage.getItem(key),
  setLocalStorageItem: (key: string, value: string) => window.localStorage.setItem(key, value),
  addEventListener: window.addEventListener,
  removeEventListener: window.removeEventListener
}): [S, React.Dispatch<React.SetStateAction<S>>] {
  const [state, setState] = React.useState<S>(initialLocalStorageState(key, getLocalStorageItem, initialState));

  React.useEffect(() => {
    const value = JSON.stringify(state);

    setLocalStorageItem(key, value);
  }, [key, state, setLocalStorageItem]);

  React.useEffect(() => {
    const listener = (event: StorageEvent) => {
      if (event.key !== key) {
        return;
      }

      const value = JSON.parse(event.newValue);

      setState(value ?? initialState);
    };

    addEventListener('storage', listener);

    return () => {
      removeEventListener('storage', listener);
    };
  }, [key, initialState, addEventListener, removeEventListener]);

  return [state, setState];
}
