class consoleReactGAWrapper {
  logGAEvent = (message, ...args) => {
    console.info(`Google Analytics: ${message}`, ...args);
  };

  page = (pathName, { isModal }) => {
    this.logGAEvent('page', pathName, { isModal });
  };

  event = (eventProps) => {
    this.logGAEvent('event', eventProps);
  };

  set = (setProps) => {
    this.logGAEvent('set', setProps);
  };
}

export default consoleReactGAWrapper;
