/**
 * Round a number to 2 decimals places,
 * e.g. 1.2345 => 1.23
 *
 * @param n number The value to round
 * @return number The value rounded to 2 decimal places
 */
export const roundTo2dp = (n: number): number => Math.round((n + Number.EPSILON) * 100) / 100;

/**
 * Gets a value un kgs and round it to two decimals. If bigger than 1k, it rounds to tons.
 * @param {*} value Value to round up
 */
export const getRoundedKgValue = (value) => {
  if (value >= 1000) {
    return roundTo2dp(value / 1000);
  }
  return Math.round(value);
};

export const getRoundedKgUnit = (value) => (value >= 1000 ? 't' : 'kg');
