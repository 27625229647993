export const PRICE_REQUESTS_LOADING = 'Price requests: loading';
export const PRICE_REQUESTS_SET_PRICE_REQUESTS = 'Price requests: Set price requests';
export const PRICE_REQUESTS_SET_ACCEPTED_PRICE_REQUEST =
  'Price requests: Set accepted price request';
export const PRICE_REQUESTS_SET_DECLINED_PRICE_REQUEST =
  'Price requests: Set declined price request';
export const PRICE_REQUESTS_ADD_NEW_PRICE_REQUEST =
  'Price requests: add a single new price request';
export const PRICE_REQUESTS_UPDATE_PRICE_REQUEST =
  'Price requests: update a single existing price request';
export const PRICE_REQUESTS_AUTHENTICATED_ACCEPT_PRICE_REQUEST =
  'Price requests: supplier accepts a price request via floating panel';
export const PRICE_REQUESTS_AUTHENTICATED_REJECT_PRICE_REQUEST =
  'Price requests: supplier rejects a price request via floating panel';
export const PRICE_REQUESTS_SHOW_PANEL = 'Price requests: Show price requests panel';
export const PRICE_REQUESTS_HIDE_PANEL = 'Price requests: Hide price requests panel';
