/**
 *  All selectors for Authentication related things
 *
 */
import { createSelector } from 'reselect';

const getAuthState = (state) => state.auth;
const getIsAuthenticated = (state) => state.auth.isAuthenticated;
const getCurrentUser = (state) => state.auth.user;
const getLogisticAddresses = (state) => state.company.logisticAddresses;
const getDestinations = (state) => state.company.destinations;

/**
 * Select the auth status of the user
 * Gives an boolean (true if user is authenticated)
 */
export const selectIsAuthenticated = createSelector(
  getIsAuthenticated,
  (isAuthenticated) => isAuthenticated
);

/**
 * Select the current login token loading flag from the state.
 * Gives a boolean (true if a token login attempt is in progress).
 */
export const selectLoginTokenLoading = createSelector(
  getAuthState,
  (auth) => auth.loginTokenLoading
);

/**
 * Select the authenticated user object
 * Gives and object with user info
 */
export const selectCurrentUser = createSelector(getCurrentUser, (currentUser) => currentUser);

/**
 * Select onboarding status
 * Gives and number from 1 to 5
 * @see core/const.js
 */
export const selectOnboardingStatus = createSelector(
  getCurrentUser,
  (currentUser) => currentUser.onboardingStatus
);

/**
 * Select current language
 * Gives a string with language info
 */
export const selectCurrentLanguage = createSelector(
  getCurrentUser,
  (currentUser) => currentUser.language
);

/**
 * Select the ID of this user's preferred addresss
 * Returns the ID of the preferred destination
 */
export const selectPreferredDestinationId = createSelector(
  getCurrentUser,
  (currentUser) => currentUser.preferredDestination
);

/**
 * Select the preferred destination object
 * Gives a string with language info
 */
export const selectPreferredDestination = createSelector(
  [getCurrentUser, getDestinations],
  (currentUser, destinations) => destinations.find((d) => d.id === currentUser.preferredDestination)
);

/**
 * Select the ID of this user's preferred addresss
 * Gives a string with language info
 */
export const selectPreferredDispatchId = createSelector(
  getCurrentUser,
  (currentUser) => currentUser.preferredDispatch
);

/**
 * Select the address
 * Gives a string with language info
 */
export const selectPreferredDispatch = createSelector(
  [getCurrentUser, getLogisticAddresses],
  (currentUser, addresses) => addresses.find((a) => a.id === currentUser.preferredDispatch)
);

export const selectIsSupplyAmba = createSelector(
  [getCurrentUser],
  (user) => !!user?.roles?.find((role) => role.name === 'supply-amba')
);

export const selectIsDemandAmba = createSelector(
  [getCurrentUser],
  (user) => !!user?.roles?.find((role) => role.name === 'demand-amba')
);
