import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { LaunchDarklyContext } from 'features/app/components/LaunchDarkly/LaunchDarklyProvider';
import analytics from '../../core/analytics';
import MixpanelWrapper from '../../core/analytics/mixpanelWrapper';
import ReactGAWrapper from '../../core/analytics/reactGAWrapper';
import ConsoleMixpanelWrapper from '../../core/analytics/consoleMixpanelWrapper';
import ConsoleReactGAWrapper from '../../core/analytics/consoleReactGAWrapper';
import { Company, User } from '../../core/models/models';
import { useIsAuthenticated } from '../auth/hooks';
import { selectCurrentUser } from '../auth/selectors';
import { selectCompany } from '../company/selectors';

import { AnalyticsContext } from './AnalyticsContext';

export enum MixpanelUserType {
  None,
  Anonymous,
  Buyer,
  Supplier,
  Amba
}

type AnalyticsProviderProps = { children: React.ReactNode };

export function getMixpanelUserType(
  isAuthenticated: boolean,
  companyType: number
): MixpanelUserType {
  if (!isAuthenticated) return MixpanelUserType.Anonymous;

  if (companyType === 0) return MixpanelUserType.Supplier;

  if (companyType === 1) return MixpanelUserType.Buyer;

  if (companyType === 9) return MixpanelUserType.Amba;

  return MixpanelUserType.None;
}

function getMixpanelToken(mixpanelUserType: MixpanelUserType): string {
  switch (mixpanelUserType) {
    case MixpanelUserType.Anonymous:
      return process.env.MIXPANEL_ANONYMOUS_TOKEN;
    case MixpanelUserType.Buyer:
      return process.env.MIXPANEL_BUYER_TOKEN;
    case MixpanelUserType.Supplier:
      return process.env.MIXPANEL_SUPPLIER_TOKEN;
    case MixpanelUserType.Amba:
      return process.env.MIXPANEL_AMBA_TOKEN;
    default:
      throw new Error('Invalid Mixpanel user type');
  }
}

function getMixpanelProject(mixpanelUserType: MixpanelUserType): string {
  switch (mixpanelUserType) {
    case MixpanelUserType.Anonymous:
      return 'anon';
    case MixpanelUserType.Buyer:
      return 'buyer';
    case MixpanelUserType.Supplier:
      return 'supplier';
    case MixpanelUserType.Amba:
      return 'amba';
    default:
      throw new Error('Invalid Mixpanel user type');
  }
}

const createMixpanelWrapper = (token: string, project: string) =>
  token && token !== 'XXXX' ? new MixpanelWrapper(token, project) : new ConsoleMixpanelWrapper();

const createReactGAWrapper = (trackingID: string) =>
  trackingID && trackingID !== 'XXXX'
    ? new ReactGAWrapper(trackingID)
    : new ConsoleReactGAWrapper();

function AnalyticsProvider({ children }: AnalyticsProviderProps): JSX.Element {
  const [mixpanelUserType, setMixpanelUserType] = React.useState(MixpanelUserType.None);
  const { isAuthenticated } = useIsAuthenticated();
  const user: User = useSelector(selectCurrentUser);
  const company: Company = useSelector(selectCompany);
  const location = useLocation();
  const { flags: featureFlags, isClientConnected: isFeatureFlagClientConnected } =
    React.useContext(LaunchDarklyContext);

  const companyId = company?.id;
  const companyName = company?.name;
  const companyType = company?.type;
  const userId = user?.id;
  const pathname = location?.pathname;

  React.useEffect(() => {
    const newMixpanelUserType = getMixpanelUserType(isAuthenticated, companyType);
    if (mixpanelUserType === newMixpanelUserType || newMixpanelUserType === MixpanelUserType.None)
      return;

    analytics.reset();
    analytics.clear();
    const token = getMixpanelToken(newMixpanelUserType);
    const analyticWrappers = [];
    analyticWrappers.push(createMixpanelWrapper(token, getMixpanelProject(newMixpanelUserType)));
    analyticWrappers.push(createReactGAWrapper(process.env.GOOGLE_ANALYTICS_TRACKINGID));
    analytics.init(analyticWrappers);

    if (userId) {
      analytics.identify(userId);
      analytics.set({ userId });
    }
    analytics.track('marketplace_loaded_app', {});
    setMixpanelUserType(newMixpanelUserType);
  }, [isAuthenticated, companyType, mixpanelUserType, userId]);

  // Update user & company properties after a change.
  React.useEffect(() => {
    if (!user || !companyId || !companyName || companyType === null) return;

    analytics.people.set({
      $name: `${user.firstname} ${user.lastname}`,
      $email: user.email,
      Company: companyName,
      Type: companyType === 0 ? 'Supplier' : 'Buyer',
      Joined: user.joined,
      FeatureFlags: featureFlags,
      isFeatureFlagClientConnected
    });
    analytics.group('company_id', companyId);
    analytics.track('user_initialized', {
      userId,
      companyId,
      featureFlags,
      isFeatureFlagClientConnected
    });
  }, [companyId, companyName, companyType, user, featureFlags, isFeatureFlagClientConnected]);

  // Log analytic event when the page path changes.
  React.useEffect(() => {
    if (!pathname) return;

    analytics.page(pathname);
  }, [pathname]);

  const value = { analytics };

  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
}

export { AnalyticsProvider };
