import dayjs from 'dayjs';
import * as ActionTypes from './actionTypes';

const INITIAL_STATE = {
  dispatchDate: dayjs().format('YYYY-MM-DD'),
  datesInterval: {
    from: dayjs().format('YYYY-MM-DD'),
    to: dayjs().add(14, 'day').format('YYYY-MM-DD')
  },
  datePickerDates: [],
  datesWithSales: [],
  lastModified: dayjs().format(),
  lastFetch: {
    datesWithSales: new Date()
  }
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ActionTypes.DATE_PICKER_SET:
      return {
        ...state,
        ...payload,
        lastModified: dayjs().format()
      };
    case ActionTypes.DATE_PICKER_CALENDAR:
      return {
        ...state,
        datesWithSales: payload,
        lastFetch: {
          ...state.lastFetch,
          datesWithSales: new Date()
        }
      };
    case ActionTypes.DATE_PICKER_DATA:
      return {
        ...state,
        datePickerDates: [
          ...payload.reduce((dates, date) => {
            const existingDateIndex = dates.findIndex(
              (existingDate) => existingDate.dispatchDate === date.dispatchDate
            );
            if (existingDateIndex !== -1) {
              dates.splice(existingDateIndex, 1, date);
            } else dates.push(date);
            return dates;
          }, state.datePickerDates)
        ]
      };
    case 'RESET':
      return INITIAL_STATE;
    default:
      return state;
  }
};
