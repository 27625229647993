import React from 'react';
import {
  useProductRequestBanner,
  UseProductRequestBanner
} from 'features/skumarketplace/hooks/useProductRequestBanner/useProductRequestBanner';

export const ProductRequestContext: React.Context<UseProductRequestBanner> =
  React.createContext(null);

export const useProductRequestContext = (): UseProductRequestBanner =>
  React.useContext(ProductRequestContext);

export interface CurrentlyOpenBuyBoxContextProviderProps {
  children: React.ReactNode;
}

export const ProductRequestContextProvider = ({
  children
}: CurrentlyOpenBuyBoxContextProviderProps): JSX.Element => {
  const productRequestBanner = useProductRequestBanner();

  return (
    <ProductRequestContext.Provider value={productRequestBanner}>
      {children}
    </ProductRequestContext.Provider>
  );
};
