import * as ActionTypes from './actionTypes';

const initialState = {
  // TOAST
  showToast: false,
  toastType: 'neutral',
  toastMessage: 'Welcome to Rooser',
  toastIcon: undefined,
  toastClickAction: undefined,
  toastPayload: undefined,
  toastActionLabel: '',
  // TOAST - NOT USED
  toastTitle: '',
  toastPosition: 'top-right'
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SHOW_TOAST:
      return { ...state, showToast: true };
    case ActionTypes.HIDE_TOAST:
      return {
        ...state,
        showToast: false,
        toastType: 'neutral',
        toastMessage: 'Welcome to Rooser',
        toastIcon: undefined,
        toastClickAction: undefined,
        toastPayload: undefined,
        toastActionLabel: ''
      };
    case ActionTypes.SET_TOAST:
      return { ...state, ...action.payload };
    case ActionTypes.LOCK_MODAL:
      return { ...state, isModalLocked: true };
    case ActionTypes.UNLOCK_MODAL:
      return { ...state, isModalLocked: false };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};
