import humps from 'humps';
import { Persistor } from 'redux-persist';
import Analytics from './analytics';
import { persistor as defaultPersistor } from './configStore';
import { Role } from './models/models';

export const tokenStorageKey = 'token';
export const rolesStorageKey = 'roles';

export interface LogoutProps {
  persistor?: Persistor;
  localStorage?: Storage;
  location?: Location;
  analytics?: any;
  isUserAction?: boolean;
  redirectToLogin?: boolean;
}

export const storeToken = (token?: string): void => {
  window.localStorage.setItem(tokenStorageKey, token);
};

export const storeRoles = (roles?: Role[]): void => {
  const roleNames = (roles || []).map(({ name }) => humps.camelize(name)).join(',');
  window.localStorage.setItem(rolesStorageKey, roleNames);
};

export const getToken = (): string => window.localStorage.getItem(tokenStorageKey) || '';

export const getRoles = (): string => window.localStorage.getItem(rolesStorageKey) || '';

export const logout = ({
  persistor = defaultPersistor,
  localStorage = window.localStorage,
  location = window.location,
  analytics = Analytics,
  isUserAction = false,
  redirectToLogin = true
}: LogoutProps = {}): void => {
  localStorage.removeItem(tokenStorageKey);
  localStorage.removeItem(rolesStorageKey);

  if (isUserAction) {
    analytics.event({ category: 'User', action: 'User Logged Out' });
  }

  analytics.reset();
  persistor.purge();
  localStorage.clear();

  if (redirectToLogin) {
    location.assign('/login');
  }
};
