import { SortOption } from 'core/sort';
import * as ActionTypes from './actionTypes';

export const initialState = {
  offers: [],
  sales: [],
  routes: [],
  sortedBy: SortOption.DELIVERY_DATE,
  loading: false,
  linkedSuppliers: [],
  filters: {
    query: '',
    favourites: false,
    withSoldOut: false
  },
  lastFetch: {
    linkedSuppliers: new Date()
  },
  deliveryDateFilter: null,
  productRequest: {
    isProductRequestOpen: false,
    productRequestDeliveryDate: null
  },
  viewPhoto: {
    isViewPhotoOpen: false,
    offerId: null
  },
  curatedOfferIds: [],
  newOffersIds: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.MARKET_OFFERS_SET:
      if (payload === state.offers) {
        return state;
      }
      return { ...state, offers: payload };
    case ActionTypes.MARKET_OFFERS_ADD:
      return {
        ...state,
        offers: [...new Set(state.offers.concat(payload))]
      };
    case ActionTypes.MARKET_OFFERS_REMOVE:
      return {
        ...state,
        offers: state.offers.filter((offerId) => offerId !== payload)
      };
    case ActionTypes.MARKET_SALES_ADD:
      return {
        ...state,
        sales: [...new Set(state.sales.concat(payload))]
      };
    case ActionTypes.MARKET_ROUTES:
      if (payload === state.routes) {
        return state;
      }
      return { ...state, routes: payload };
    case ActionTypes.MARKET_SET_FILTERS:
      if (payload === state.filters) {
        return state;
      }
      return { ...state, filters: { ...state.filters, ...payload } };
    case ActionTypes.MARKET_SET_LOADING:
      return { ...state, loading: !!payload };
    case ActionTypes.MARKET_SORT_BY:
      return { ...state, sortedBy: payload };
    case ActionTypes.MARKET_LINKED_SUPPLIERS:
      return {
        ...state,
        linkedSuppliers: payload,
        lastFetch: {
          ...state.lastFetch,
          linkedSuppliers: new Date()
        }
      };
    case ActionTypes.MARKET_SET_DELIVERY_DATE_FILTER:
      return {
        ...state,
        deliveryDateFilter: payload
      };
    // Reset all marketplace-related translated data
    case ActionTypes.SWITCH_LANGUAGE:
      return {
        ...state,
        lastFetch: {
          ...state.lastFetch
        }
      };
    case ActionTypes.MARKET_CLEAR_SEARCH:
      return {
        ...state,
        filters: initialState.filters
      };
    case ActionTypes.MARKET_SET_IS_PRODUCT_REQUEST_OPEN:
      return {
        ...state,
        productRequest: {
          ...state.productRequest,
          isProductRequestOpen: payload
        },
        viewPhoto: {
          ...state.viewPhoto,
          isViewPhotoOpen: false
        }
      };
    case ActionTypes.MARKET_SET_PRODUCT_REQUEST_DELIVERY_DATE:
      return {
        ...state,
        productRequest: {
          ...state.productRequest,
          productRequestDeliveryDate: payload
        }
      };
    case ActionTypes.MARKET_SET_CURATED_OFFERS:
      return { ...state, curatedOfferIds: payload };
    case ActionTypes.MARKET_ADD_CURATED_OFFER:
      return { ...state, curatedOfferIds: state.curatedOfferIds.concat(payload) };
    case ActionTypes.MARKET_SET_REAL_TIME_NEW_OFFER_IDS:
      return { ...state, newOffersIds: payload };
    case ActionTypes.MARKET_OFFERS_RESET:
      return { ...state, offers: initialState.offers };
    case ActionTypes.MARKET_CLEAR_ALL_FITLERS:
      return {
        ...state,
        filters: initialState.filters,
        deliveryDateFilter: initialState.deliveryDateFilter
      };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};
