import dayjs from 'dayjs';
import createThunkErrorHandlerMiddleware from 'redux-thunk-error-handler';
import Analytics from 'core/analytics';
import i18n from 'core/i18n';
import * as MarketActionTypes from 'features/marketplace/actionTypes';
import * as DatePickerActionTypes from 'features/datepicker/actionTypes';
import { errorToast } from 'features/modal/actions';
import * as ModalActionTypes from 'features/modal/actionTypes';
import { formatProductForTracking } from './helpers';

/**
 * Check  if the actions carry entities and update the targeted reducers if it does
 * @param {*} state
 */
const entitiesMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.entities) {
      dispatch({
        type: 'ENTITIES_SET',
        payload: action.entities
      });
    }
    if (action.deleteId && action.entityName) {
      // @TODO test this
      dispatch({
        type: 'ENTITIES_DELETE',
        payload: { entityId: action.deleteId, entityName: action.entityName }
      });
    }
    next(action);
  };

/**
 * Track information related to user activies on Rooser
 * @param {*} store
 */
const trackingMiddleware = () => (next) => (action) => {
  switch (action.type) {
    case DatePickerActionTypes.DATE_PICKER_SET:
      Analytics.track('dispatch_date_changed_app', {
        date: action.payload.dispatchDate,
        day: dayjs(action.payload.dispatchDate, 'YYYY-MM-DD').format('dddd'),
        isYesterday:
          action.payload.dispatchDate === dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
        onPage: window.location.pathname
      });
      break;
    case ModalActionTypes.OPEN_MODAL:
      if (
        action.payload.modalType === 'offer_detail' &&
        action.payload.modalProps.canRequestPrice
      ) {
        Analytics.track('marketplace_request_price_clicked_app', {
          ...formatProductForTracking(action.payload.modalProps.offer.product),
          ...action.payload.modalProps.trackingData,
          supplierPerformanceScore: action.payload.modalProps.offer.supplierPerformanceScore
        });
      }
      break;
    default:
      break;
  }

  next(action);
};

export const onError = (err) => (dispatch) => {
  if (dispatch) {
    dispatch(errorToast(i18n.t('generic.toast.errorTitle', 'Oops! Something went wrong')));
  }

  throw err;
};

const errorHandlerMiddleware = createThunkErrorHandlerMiddleware({ onError });

export { entitiesMiddleware, errorHandlerMiddleware, trackingMiddleware };
