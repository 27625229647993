import isNil from 'lodash/isNil';
import dayjs from 'dayjs';
import i18n from 'core/i18n';

import WeightLogic from 'core/weight/weight';
import { labelForDate } from './const';
import { qualityGradeFromValue } from './qualityGrades.ts';

/**
 *  -----------------------------------------------------------------
 *          SORTING CONSTS
 *  -----------------------------------------------------------------
 */

export const COD = 'cod';
export const SKREI = 'Skrei';

export const SortOption = Object.freeze({
  DELIVERY_DATE: 0,
  QUALITY: 1,
  NEWEST_OFFERS: 2,
  SCARCITY: 3,
  PRICE: 4,
  RELEVANCE: 5
});

export function sortGroupAttribute(sortedBy) {
  switch (sortedBy) {
    case SortOption.DELIVERY_DATE:
      return 'deliveryDate';
    case SortOption.NEWEST_OFFERS:
      return 'updatedAt';
    case SortOption.PRICE:
      return 'finalPrice';
    case SortOption.QUALITY:
      return 'quality';
    case SortOption.SCARCITY:
      return 'quantityLeft';
    default:
      return 'deliveryDate';
  }
}

// Newest offers groups.

const ONE_HOUR = 3600000;

const newestOfferGroupsMaxAge = [1, 2, 4, 24];

const lastNewestOfferGroup = 'older';

function groupForNewestOffers(updatedAt) {
  const age = dayjs().diff(updatedAt);
  return (
    newestOfferGroupsMaxAge.reduce(
      (result, maxAge) => result || (age < maxAge * ONE_HOUR ? `${maxAge}` : null),
      null
    ) || lastNewestOfferGroup
  );
}

function labelForNewestOffersGroup(count) {
  switch (count) {
    case lastNewestOfferGroup:
      return i18n.t('sortResults.newestOlderThanADay.label', 'Older than 1 day');
    case '1':
      return i18n.t('sortResults.newestOffersLastHour.label', 'Updated in the last hour');
    default:
      return i18n.t(
        'sortResults.newestOffersLastXHours.label',
        'Updated in the last {{ count }} hours',
        { count }
      );
  }
}

// Price groups.

const priceGroupMaxValues = [6, 9, 12, 15];
const lastPriceGroup = 16; // arbitrary value>15 to denote the group 'more than 15' when sorting by price

const lastPriceGroupValue = priceGroupMaxValues[priceGroupMaxValues.length - 1];

function groupForPrice(price) {
  return (
    priceGroupMaxValues.reduce(
      (result, maxValue) => result || (price < maxValue ? maxValue : null),
      null
    ) || lastPriceGroup
  );
}

function labelForPriceGroup(price) {
  switch (price) {
    case lastPriceGroup:
      return i18n.t('sortResults.priceMoreThan.label', '> € {{ price }}/kg', {
        price: lastPriceGroupValue
      });
    default:
      return i18n.t('sortResults.priceLessThan.label', '< € {{ price }}/kg', { price });
  }
}

// Quantity Groups:
function labelForQuantityGroup(quantityLeft) {
  if (quantityLeft < 15) {
    return i18n.t('sortResults.quantityLessThanX.label', 'Less than 15 available', {
      quantity: 15
    });
  }
  if (quantityLeft >= 15 && quantityLeft < 30) {
    return i18n.t('sortResults.quantityLessThanX.label', 'Less than 30 available', {
      quantity: 30
    });
  }
  if (quantityLeft >= 30 && quantityLeft < 50) {
    return i18n.t('sortResults.quantityLessThanX.label', 'Less than 50 available', {
      quantity: 50
    });
  }
  if (quantityLeft >= 50 && quantityLeft < 100) {
    return i18n.t('sortResults.quantityLessThanX.label', 'Less than 100 available', {
      quantity: 100
    });
  }
  if (quantityLeft >= 100) {
    return i18n.t('sortResults.quantityMoreThan100.label', 'More than 100 available');
  }
}

// Relevance groups.
const purchasedGroup = 'purchased';
const otherGroup = 'other';

function groupForRelevance(count) {
  return count > 0 ? purchasedGroup : otherGroup;
}

function labelForRelevanceGroup(group) {
  return group === purchasedGroup
    ? i18n.t('sortResults.relevanceBasedOnPurchases.label', 'Based on your orders')
    : i18n.t('sortResults.relevanceOffersYouMightLike.label', 'More offers you might like');
}

function sortGroupKey(sortedBy, value) {
  const date = dayjs(value);
  switch (sortedBy) {
    case SortOption.NEWEST_OFFERS:
      return groupForNewestOffers(value);
    case SortOption.PRICE:
      return groupForPrice(value);
    case SortOption.SCARCITY:
      return labelForQuantityGroup(value);
    case SortOption.RELEVANCE:
      return groupForRelevance(value);
    case SortOption.DELIVERY_DATE:
      return date.format('YYYY-MM-DD');
    default:
      return value;
  }
}

export const labelForGroup = (sortOption, key) => {
  switch (sortOption) {
    case SortOption.DELIVERY_DATE:
      return labelForDate(key);
    case SortOption.NEWEST_OFFERS:
      return labelForNewestOffersGroup(key);
    case SortOption.PRICE:
      return labelForPriceGroup(key);
    case SortOption.SCARCITY:
      return labelForQuantityGroup(key);
    case SortOption.QUALITY:
      return qualityGradeFromValue(Number(key))?.shortText;
    case SortOption.RELEVANCE:
      return labelForRelevanceGroup(key);
    default:
      return key;
  }
};

export function groupOffersBySortOption(offers, sortOption) {
  return offers.reduce((result, offer) => {
    const attribute = sortGroupAttribute(sortOption);
    const key = sortGroupKey(sortOption, offer[attribute]);
    const offerGroup = result[key] || {
      label: labelForGroup(sortOption, sortOption === SortOption.SCARCITY ? offer[attribute] : key),
      offers: []
    };
    const newOffers = [...offerGroup.offers, offer];
    return { ...result, [key]: { ...offerGroup, offers: newOffers } };
  }, {});
}

/**
 *  -----------------------------------------------------------------
 *           SORTING HELPER METHODS
 *  -----------------------------------------------------------------
 */

export const isSkreiCod = (product) =>
  product.species.trackingKey === COD &&
  product.certificates?.some((certificate) => certificate.name === SKREI);

/**
 * Sort function to sort Product from bigger to smaller
 *
 * Except for the Product in Piece per kg unit, where we revsere
 *
 * @param {*} a -> Product
 * @param {*} b -> Product
 */
export const sortProducts = (a, b) => {
  if (!a || !b) {
    return 0;
  }

  // By Species name
  if (a.species?.name > b.species?.name) return 1;
  if (a.species?.name < b.species?.name) return -1;

  // Special case for Skrei Cod
  if (a.species?.trackingKey === COD) {
    if (isSkreiCod(a) && !isSkreiCod(b)) return 1;
    if (!isSkreiCod(a) && isSkreiCod(b)) return -1;
  }

  // By Type
  if (a.type?.order > b.type?.order) return 1;
  if (a.type?.order < b.type?.order) return -1;

  // By Grade
  const { aUnit, aMinAmount, aMaxAmount } = a.grade;
  const { bUnit, bMinAmount, bMaxAmount } = b.grade;
  if (
    aUnit === WeightLogic.unitValues.piecePerKilogram &&
    bUnit === WeightLogic.unitValues.piecePerKilogram
  ) {
    if (aMinAmount + aMaxAmount > bMinAmount + bMaxAmount) return -1;
    if (aMinAmount + aMaxAmount < bMinAmount + bMaxAmount) return 1;
  }
  if (WeightLogic.convertGradeMinToGrams(a.grade) > WeightLogic.convertGradeMinToGrams(b.grade))
    return -1;
  if (WeightLogic.convertGradeMinToGrams(a.grade) < WeightLogic.convertGradeMinToGrams(b.grade))
    return 1;

  // By Variation
  if (a.variation?.order > b.variation?.order) return 1;
  if (a.variation?.order < b.variation?.order) return -1;

  // By Country
  const countryA = a.harvestCountry || a.country;
  const countryB = b.harvestCountry || b.country;

  if (countryA > countryB) return 1;
  if (countryA < countryB) return -1;

  // By Fishing method
  if (a.fishingMethod?.name > b.fishingMethod?.name) return 1;
  if (a.fishingMethod?.name < b.fishingMethod?.name) return -1;

  // By Fishing area
  if (a.fishingArea?.code > b.fishingArea?.code) return 1;
  if (a.fishingArea?.code < b.fishingArea?.code) return -1;

  return 0;
};

/**
 * Sort function to sort Skus from bigger to smaller
 *
 * Except for the Product in Piece per kg unit, where we revsere
 *
 * @param {*} a -> Product
 * @param {*} b -> Product
 */
export const sortSku = (a, b) => {
  // By Species name
  if (a.species.name > b.species.name) return 1;
  if (a.species.name < b.species.name) return -1;

  // By Type
  if (a.type.order > b.type.order) return 1;
  if (a.type.order < b.type.order) return -1;

  // By variation
  if (a.variation.order > b.variation.order) return 1;
  if (a.variation.order < b.variation.order) return -1;

  // By Grade group
  if (a.gradeGroup.label < b.gradeGroup.label) return 1;
  if (a.gradeGroup.label > b.gradeGroup.label) return -1;

  // By Nature
  if (a.nature.label > b.nature.label) return 1;
  if (a.nature.label < b.nature.label) return -1;

  return 0;
};

/**
 * Sort function to sort offers
 *
 * @param {*} a -> Product
 * @param {*} b -> Product
 */
export const sortOffers = (a, b) => {
  const order = sortProducts(a.product, b.product);

  if (order === 0) {
    if (a.sellingPrice > b.sellingPrice) return 1;
    if (a.sellingPrice < b.sellingPrice) return -1;
  }

  return order;
};

/**
 * Compares two elements and sort them from lower to higher by default
 * @param {string | number} a value A
 * @param {string | number} b value B
 * @param {['asc', 'desc']} [order] order
 * @return int
 */
export const sortByValues = (a, b, order = 'asc') => {
  if (isNil(a) || isNil(b)) return 0;

  if (order === 'asc') {
    if (a < b) return -1;
    if (a > b) return 1;
  }

  if (order === 'desc') {
    if (a > b) return -1;
    if (a < b) return 1;
  }

  return 0;
};

/**
 * Returns the English tracking label for a given sort option.
 *
 * @param {number} sortOption
 * @returns {string}
 */
export const getTrackingLabelForSortOption = (sortOption) => {
  switch (sortOption) {
    case SortOption.DELIVERY_DATE:
      return 'Delivery date';
    case SortOption.QUALITY:
      return 'Quality';
    case SortOption.NEWEST_OFFERS:
      return 'Newest offers';
    case SortOption.SCARCITY:
      return 'Volume';
    case SortOption.PRICE:
      return 'Lowest price';
    case SortOption.RELEVANCE:
      return 'Relevance';
    default:
      return undefined;
  }
};

/**
 * Sort function to sort Grade from bigger to smaller
 *
 * @param {*} a -> Grade
 * @param {*} b -> Grade
 */
export const sortByGrade = (a, b) => {
  const { aUnit, aMinAmount, aMaxAmount } = a;
  const { bUnit, bMinAmount, bMaxAmount } = b;

  if (
    aUnit === WeightLogic.unitValues.piecePerKilogram &&
    bUnit === WeightLogic.unitValues.piecePerKilogram
  ) {
    if (aMinAmount + aMaxAmount > bMinAmount + bMaxAmount) return -1;
    if (aMinAmount + aMaxAmount < bMinAmount + bMaxAmount) return 1;
  }
  if (WeightLogic.convertGradeMinToGrams(a) > WeightLogic.convertGradeMinToGrams(b)) return -1;
  if (WeightLogic.convertGradeMinToGrams(a) < WeightLogic.convertGradeMinToGrams(b)) return 1;

  return 0;
};
