import React from 'react';
import has from 'lodash.has';
import { useTranslation } from 'react-i18next';
import { QueryCache, QueryClient } from 'react-query';
import { errorToast } from '../../../modal/actions';
import { useAppDispatch } from '../../../../core/configStore';

export interface UseQueryClient {
  queryClient: QueryClient;
}

const useQueryClient = (): UseQueryClient => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onError = React.useCallback(
    (error: unknown): void => {
      dispatch(errorToast(t('generic.toast.errorTitle', 'Oops! Something went wrong')));
      throw error;
    },
    [dispatch, t]
  );

  const retry = React.useCallback((_: number, error: unknown): boolean => {
    if (has(error, 'response.status')) {
      return false;
    }

    return true;
  }, []);

  const queryClient = React.useMemo(
    () =>
      new QueryClient({
        queryCache: new QueryCache(),
        defaultOptions: {
          queries: {
            retry,
            onError
          },
          mutations: {
            onError
          }
        }
      }),
    [onError, retry]
  );

  return { queryClient };
};

export default useQueryClient;
