import { useAppSelector } from '../../../../core/configStore';
import { selectCurrentLanguage } from '../../../auth/selectors';
import { selectTranslationLoading } from '../../selectors';

export type Language = 'en' | 'fr';

export interface UseLanguage {
  translationLoading: boolean;
  userLanguage?: Language;
}

export default (): UseLanguage => {
  const translationLoading = useAppSelector(selectTranslationLoading);
  const userLanguage = useAppSelector(selectCurrentLanguage);

  return { translationLoading, userLanguage };
};
