import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useAuth from 'features/auth/hooks/useAuth';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { FEATURE_FLAG_DEFAULT_VALUES, FeatureFlagName } from './FeatureFlagDefaultValues';

type LaunchDarklyContextType = {
  flags: typeof FEATURE_FLAG_DEFAULT_VALUES;
  isClientConnected: boolean;
};
const LaunchDarklyContextDefaultValue: LaunchDarklyContextType = {
  flags: FEATURE_FLAG_DEFAULT_VALUES,
  isClientConnected: false
};

export const LaunchDarklyContext = createContext(LaunchDarklyContextDefaultValue);

export const LaunchDarklyProvider = ({ children }) => {
  const { user } = useAuth();
  const [client, setClient] = useState<LDClient.LDClient | null>(null);
  const [clientInitialized, setClientInitialized] = useState(false);
  const [flags, setFlags] = useState(FEATURE_FLAG_DEFAULT_VALUES);

  const setAllFlagsFromLD = useCallback(
    (ldClient: LDClient.LDClient) => {
      const updatedFlags = { ...FEATURE_FLAG_DEFAULT_VALUES };
      Object.keys(FEATURE_FLAG_DEFAULT_VALUES).forEach(
        // We do want to reassign in this case as we are fetching the real values from LaunchDarkly
        // eslint-disable-next-line no-param-reassign
        (key) => (updatedFlags[key] = ldClient.variation(key, FEATURE_FLAG_DEFAULT_VALUES[key]))
      );
      setFlags(updatedFlags);
    },
    [setFlags]
  );

  const context = useMemo(
    () =>
      user
        ? {
            kind: 'multi',
            user: {
              key: user.companyId.toString(),
              userId: user.id,
              companyId: user.companyId,
              companyName: user.company.name,
              firsttName: user.firstname,
              lastName: user.lastname,
              email: user.email
            },
            company: {
              key: `company-key-${user.companyId}`,
              name: user.company.name
            },
            user_v2: {
              key: `user-key-${user.id}`,
              name: user.email,
              companyId: user.companyId,
              companyName: user.company.name,
              firstName: user.firstname,
              lastName: user.lastname,
              email: user.email
            }
          }
        : {
            kind: 'user',
            anonymous: true
          },
    [user]
  );

  useEffect(() => {
    let ldClient: LDClient.LDClient | null = null;
    const initializeClient = async () => {
      ldClient = LDClient.initialize(process.env.LAUNCH_DARKLY_CLIENT_ID, context);

      ldClient.on('ready', () => {
        setClient(ldClient);
        setAllFlagsFromLD(ldClient);
        setClientInitialized(true);
      });

      ldClient.on('change', () => {
        setAllFlagsFromLD(ldClient);
      });
    };

    initializeClient();

    return () => {
      ldClient.close();
      setClient(null);
      setClientInitialized(false);
    };
  }, []);

  useEffect(() => {
    if (clientInitialized) {
      client.identify(context);
      setAllFlagsFromLD(client);
    }
  }, [user]);

  if (!user) {
    return <>{children}</>;
  }

  return (
    <LaunchDarklyContext.Provider value={{ flags, isClientConnected: clientInitialized }}>
      {children}
    </LaunchDarklyContext.Provider>
  );
};

export function useFeatureFlag(name: FeatureFlagName) {
  const { flags } = useContext(LaunchDarklyContext);

  /** HACK, HACK, HACK!
   * The only reason we are keeping the feature flag override via the
   * URL is for the e2e tests. I could not figure out a better way to
   * do this at this time.
   *
   * Note: If you want to override flags locally, you can always change
   * the value in FeatureFlagDefaultValues.ts.
   */
  if (process.env.LAUNCH_DARKLY_OVERRIDE_ENABLED === 'true') {
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const params = new URLSearchParams(url.search);
    return params.get(name) === 'true';
  }
  return flags[name];
}
