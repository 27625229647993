import dayjs from 'dayjs';
import http from 'core/http';
import * as AuthActionTypes from 'features/auth/actionTypes';
import * as MarketActionTypes from 'features/marketplace/actionTypes';
import { companyType } from 'core/const';
import i18n from 'core/i18n';
import isEmpty from 'lodash/isEmpty';
import * as ActionTypes from './actionTypes';

// Caching delay in minutes
const endpoints = {
  references: '/api/v1/product-references',
  certifications: '/api/v1/certifications',
  certificates: '/api/v1/product-certificates',
  species: '/api/v1/species',
  types: '/api/v1/types',
  variations: '/api/v1/variations',
  grades: '/api/v1/grades',
  packings: '/api/v1/packings',
  fishingMethods: '/api/v1/fishing-methods',
  health: '/health',
  switchLanguage: (locale) => `/api/v1/switch-locale/${locale}`
};

/**
 *  Caching, Check if a date is expired
 *  @param date The date object to compare
 *  @param minutes number of minutes to check
 */
const isExpired = (date, delay = 60) => dayjs().diff(dayjs(date), 'minutes') >= delay;

/**
 * ----------------------------------------------------------------------------
 *
 *              DROPDOWN DATA LISTS
 *
 * ----------------------------------------------------------------------------
 */

/**
 * Fetch Supplier certifications
 *
 * Get a list of all suppliers certifications
 * -> Only fetches if it hasn't been fetched for an hour
 */
export const fetchCertifications = () => (dispatch) =>
  http.get(endpoints.certifications).then((res) => {
    dispatch({ type: ActionTypes.APP_DATA_CERTIFICATIONS, payload: res.data });
  });

/**
 * Fetch Product references
 *
 * All available combinations of species, types, variations, packing and fishing method
 */
export const fetchProductReferences = () => (dispatch, getState) => {
  const { lastFetch, productReferences } = getState().app;
  if (isExpired(lastFetch.productReferences) || isEmpty(productReferences)) {
    return http.get(endpoints.references).then((res) => {
      dispatch({
        type: ActionTypes.APP_DATA_PRODUCT_REFERENCES,
        payload: res.data
      });
    });
  }
};

export const switchLanguage = (lang) => (dispatch, getState) => {
  dispatch({ type: ActionTypes.APP_DATA_TRANSLATION_LOADING, payload: true });

  const { user, isAuthenticated } = getState().auth;
  const { company } = getState().company;

  return http
    .get(endpoints.switchLanguage(lang))
    .then(() => {
      // Buyer-specific translated data
      if (company && company.type === companyType.buyer) {
        dispatch({ type: MarketActionTypes.SWITCH_LANGUAGE });
      }
    })
    .then(() => {
      i18n.changeLanguage(lang);
      if (isAuthenticated && user) {
        dispatch({ type: ActionTypes.SWITCH_LANGUAGE });
        dispatch({
          type: AuthActionTypes.AUTH_UPDATE_USER,
          payload: { ...user, language: lang }
        });
      }
    })
    .finally(() => dispatch({ type: ActionTypes.APP_DATA_TRANSLATION_LOADING, payload: false }));
};

export const toggleOverlay = (payload) => ({
  type: ActionTypes.APP_OVERLAY,
  payload
});

/**
 * Check if api responds with 200
 */
export const checkApiHealth = () => () => http.get(endpoints.health).then((res) => res.data);
