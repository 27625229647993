import { useAppSelector } from '../../../core/configStore';
import { Company, UnauthenticatedUser, User } from '../../../core/models/models';
import { selectCompany } from '../../company/selectors';
import {
  selectCurrentUser,
  selectIsAuthenticated,
  selectIsDemandAmba,
  selectIsSupplyAmba
} from '../selectors';

export interface UseAuth {
  isAuthenticated: boolean;
  isDemandAmba: boolean;
  isSupplyAmba: boolean;
  isExternalUser: boolean;
  company: Company | null;
  user?: User;
  unauthenticatedUser?: UnauthenticatedUser;
}

export const useAuth = (): UseAuth => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const company = useAppSelector(selectCompany);
  const user = useAppSelector(selectCurrentUser);
  const isSupplyAmba = useAppSelector(selectIsSupplyAmba);
  const isDemandAmba = useAppSelector(selectIsDemandAmba);
  const isExternalUser = isAuthenticated && !isDemandAmba && !isSupplyAmba;

  return {
    isAuthenticated,
    isDemandAmba,
    isSupplyAmba,
    isExternalUser,
    company,
    unauthenticatedUser: !user.id ? user : null,
    user: user.id ? user : null
  };
};

export default useAuth;
