import React from 'react';
import { Helmet } from 'react-helmet';
import useLanguage from '../../hooks/useLanguage/useLanguage';

const LangHelmet: React.FunctionComponent<Record<string, never>> = () => {
  const { userLanguage } = useLanguage();

  return (
    <Helmet>
      <html lang={userLanguage} />
    </Helmet>
  );
};

export default LangHelmet;
