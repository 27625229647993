import React from 'react';
import { Illustration, IllustrationProps } from '@rooser/reef';

const AnimatedSpinner = ({ cy, size = 'regular' }: IllustrationProps): JSX.Element => {
  return <Illustration cy={cy} name="spinner" size={size} />;
};

AnimatedSpinner.defaultProps = {
  size: 'regular'
};

export default AnimatedSpinner;
