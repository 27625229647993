import React, { lazy } from 'react';

export const loadWithForceRefresh = (
  componentImport: () => Promise<any>,
  componentName: string
): Promise<any> =>
  new Promise((resolve, reject) => {
    const key = `force-refreshed-${componentName}`;
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.sessionStorage.getItem(key) || 'false'
    );

    componentImport()
      // if component resolves,
      // set the session storage key to false
      // and return the component
      .then((component) => {
        window.sessionStorage.setItem(key, 'false');
        resolve(component);
      })
      .catch((error) => {
        // force reload if not yet done
        if (!pageHasAlreadyBeenForceRefreshed) {
          window.sessionStorage.setItem(key, 'true');
          window.location.reload();
          reject();
        }

        // at this point, page has been forced refreshed,
        // Assuming user is already using latest version,
        // let app crash and throw error so sentrty can catch
        reject(error);
      });
  });

export const lazyLoadWithRetry = (
  componentImport: () => Promise<any>,
  componentName: string
): React.LazyExoticComponent<React.ComponentType<any>> =>
  lazy(() => loadWithForceRefresh(componentImport, componentName));
