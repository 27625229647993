export interface QualityGrade {
  shortText: string;
  text: string;
  value: number;
}

export const qualityGrades: QualityGrade[] = [
  {
    value: 1,
    text: 'Extra',
    shortText: 'E'
  },
  {
    value: 2,
    text: 'A+',
    shortText: 'A+'
  },
  {
    value: 3,
    text: 'A',
    shortText: 'A'
  },
  {
    value: 4,
    text: 'A-',
    shortText: 'A-'
  },
  {
    value: 5,
    text: 'B',
    shortText: 'B'
  }
];

export function qualityGradeFromValue(qualityValue: number): QualityGrade {
  return qualityGrades.find(({ value }) => qualityValue === value);
}

export const optionsForSelect: { label: string; value: number }[] = qualityGrades.map(
  // TODO: Use type from Reef when it is available.
  ({ shortText: label, value }: QualityGrade) => ({
    label,
    value
  })
);

export function selectedOption(qualityValue: number): { label: string; value: number } {
  // TODO: Use type from Reef when it is available.
  return optionsForSelect.find(({ value }) => qualityValue === value);
}

export function selectedOptionFromLabel(qualityLabel: string): { label: string; value: number } {
  // TODO: Use type from Reef when it is available.
  return optionsForSelect.find(({ label }) => qualityLabel === label);
}

export default qualityGrades;
