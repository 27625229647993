import * as ActionTypes from './actionTypes';

const initialState = {
  company: null,
  address: null,
  logisticAddresses: [],
  destinations: [],
  certifications: [],
  lastFetch: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.COMPANY_UPDATE:
      if (action.payload === state.company) {
        return state;
      }
      return { ...state, company: action.payload };
    case ActionTypes.COMPANY_HEADQUARTER:
      return { ...state, address: action.payload };
    case ActionTypes.COMPANY_LOGISTIC:
      return {
        ...state,
        logisticAddresses: action.payload,
        lastFetch: { ...state.lastFetch, logisticAddresses: new Date() }
      };
    case ActionTypes.COMPANY_DESTINATIONS:
      return { ...state, destinations: action.payload };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};
