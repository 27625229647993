export type FeatureFlagName =
  | 'release-allow-supply-price-amended-by-ambas'
  | 'release-amba-tools-with-online-users'
  | 'release-buyer-favourites'
  | 'release-catch-date-field'
  | 'release-certainty-tags'
  | 'release-cheapest-price-offers-column'
  | 'release-commercial-flags-marketplace-page'
  | 'release-country-on-product-request-form'
  | 'release-dispatch-bulk-accept-confirm'
  | 'release-dispatch-instead-of-delivery'
  | 'release-display-new-product-fields'
  | 'release-editable-batch-number'
  | 'release-enable-sales-editing-by-role'
  | 'release-grade-selector'
  | 'release-historic-purchase-section'
  | 'release-link-volume'
  | 'release-list-from-recent-activity'
  | 'release-live-market'
  | 'release-live-market-new-count-badge'
  | 'release-marketplace-best-sellers'
  | 'release-marketplace-filters'
  | 'release-new-create-product-form'
  | 'release-po-number-on-orders'
  | 'release-recent-searches'
  | 'release-shared-stock-across-offers-feature'
  | 'release-sku-marketplace-must-go-opportunity-external'
  | 'release-sku-offers-page'
  | 'release-sold-out-state'
  | 'release-sort-buyer-offers-autocomplete'
  | 'release-sort-supplier-skus-autocomplete'
  | 'release-supplier-defined-bulk-discounts-buyer'
  | 'release-supplier-defined-bulk-discounts-supplier'
  | 'release-updated-marketplace-categories'
  | 'release-visual-cues-for-dispatch-actions'
  | 'release-recommended-offers-section'
  | 'release-horizontal-recommender-section'
  | 'release-bulk-discount-with-price'
  | 'release-show-post-purchase-modal';

export const FEATURE_FLAG_DEFAULT_VALUES: Record<FeatureFlagName, any> = {
  'release-allow-supply-price-amended-by-ambas': false,
  'release-amba-tools-with-online-users': false,
  'release-buyer-favourites': false,
  'release-catch-date-field': false,
  'release-certainty-tags': true,
  'release-cheapest-price-offers-column': false,
  'release-commercial-flags-marketplace-page': false,
  'release-country-on-product-request-form': false,
  'release-dispatch-bulk-accept-confirm': false,
  'release-dispatch-instead-of-delivery': false,
  'release-display-new-product-fields': false,
  'release-editable-batch-number': false,
  'release-enable-sales-editing-by-role': false,
  'release-grade-selector': false,
  'release-historic-purchase-section': false,
  'release-link-volume': false,
  'release-list-from-recent-activity': false,
  'release-live-market': false,
  'release-live-market-new-count-badge': false,
  'release-marketplace-best-sellers': false,
  'release-marketplace-filters': false,
  'release-new-create-product-form': false,
  'release-po-number-on-orders': false,
  'release-recent-searches': false,
  'release-shared-stock-across-offers-feature': false,
  'release-sku-marketplace-must-go-opportunity-external': false,
  'release-sku-offers-page': true,
  'release-sold-out-state': false,
  'release-sort-buyer-offers-autocomplete': false,
  'release-sort-supplier-skus-autocomplete': false,
  'release-supplier-defined-bulk-discounts-buyer': false,
  'release-supplier-defined-bulk-discounts-supplier': false,
  'release-updated-marketplace-categories': false,
  'release-visual-cues-for-dispatch-actions': false,
  'release-recommended-offers-section': false,
  'release-horizontal-recommender-section': false,
  'release-bulk-discount-with-price': false,
  'release-show-post-purchase-modal': false
};
