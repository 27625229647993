import React from 'react';
import { useLocalStorageFlag } from 'crypsis/useLocalStorageFlag/useLocalStorageFlag';
import { StringFlagValue } from 'crypsis/StringFlagValue/StringFlagValue';

export function FlagOverridePanel({ location = window.location }: { location?: Location }): React.JSX.Element {
  const { flags, setFlag } = useLocalStorageFlag();
  const isOpen = (new URL(location.toString())).searchParams.get('editFlags') !== null;

  return isOpen && (
    <div style={{
      borderTop: '1px solid black',
      backgroundColor: 'white',
      padding: '16px',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 100000
    }}>
      {Object.entries(flags).map(([name, value]) => (
        <p key={name}><i>{name}</i>: <StringFlagValue value={value} onChange={(newValue) => setFlag(name, newValue)} /></p>
      ))}
    </div>
  );
}
