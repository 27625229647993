import React from 'react';

export interface LocalStorageFlag {
  enabled: boolean;
  getFlag: (name: string) => string;
  setFlag: (name: string, value: string) => void;
  flags: { [name: string]: string };
}

export const LocalStorageFlagContext = React.createContext<LocalStorageFlag | undefined>(undefined);
