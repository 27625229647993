import React from 'react';

import {
  useCompanySelector,
  UseCompanySelector
} from '../../hooks/useCompanySelector/useCompanySelector';

export const CompanySelectorContext: React.Context<UseCompanySelector> = React.createContext(null);

export const useCompanySelectorContext = (): UseCompanySelector =>
  React.useContext(CompanySelectorContext);

export interface CompanySelectorContextProviderProps {
  children: React.ReactNode;
}

export const CompanySelectorContextProvider = ({
  children
}: CompanySelectorContextProviderProps): JSX.Element => {
  const contextValue = useCompanySelector();

  return (
    <CompanySelectorContext.Provider value={contextValue}>
      {children}
    </CompanySelectorContext.Provider>
  );
};
