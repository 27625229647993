import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AmbaSidebarData, Company } from '../../../../core/models/models';
import { demandPaths, supplyPaths } from '../../../../core/const';
import Analytics from '../../../../core/analytics';

export interface UseCompanySelector {
  buyer?: Company;
  company: Company;
  isLoading?: boolean;
  isFetching?: boolean;
  onSelectBuyer: (value: Company | AmbaSidebarData) => void;
  onSelectCompany: (value: Company | AmbaSidebarData) => void;
  onSelectSupplier: (value: Company | AmbaSidebarData) => void;
  refetch?: () => void;
  supplier?: Company;
}

export const useCompanySelector = (): UseCompanySelector => {
  const cachedCompany = localStorage.getItem('company');
  const cachedBuyer = localStorage.getItem('buyer');
  const cachedSupplier = localStorage.getItem('supplier');

  const [company, setCompany] = useState(cachedCompany ? JSON.parse(cachedCompany) : null);
  const [buyer, setBuyer] = useState(cachedBuyer ? JSON.parse(cachedBuyer) : null);
  const [supplier, setSupplier] = useState(cachedSupplier ? JSON.parse(cachedSupplier) : null);

  const location = useLocation();

  const onSelectCompany = (company) => {
    const companyId = company.id || company.companyId;
    setCompany({ ...company, id: companyId });
    localStorage.setItem(
      'company',
      JSON.stringify({
        id: companyId,
        name: company.name,
        country: company.country,
        type: company.type
      })
    );
    Analytics.track('amba_tools_company_selected', {
      id: companyId,
      name: company.name
    });
  };

  const onSelectBuyer = (company) => {
    const companyId = company.id || company.companyId;
    setBuyer({ ...company, id: companyId });
    localStorage.setItem(
      'buyer',
      JSON.stringify({
        id: companyId,
        name: company.name,
        country: company.country,
        type: company.type
      })
    );
    Analytics.track('amba_tools_company_selected', {
      id: companyId,
      name: company.name
    });
  };

  const onSelectSupplier = (company) => {
    const companyId = company.id || company.companyId;
    setSupplier({ ...company, id: companyId });
    localStorage.setItem(
      'supplier',
      JSON.stringify({
        id: companyId,
        name: company.name,
        country: company.country,
        type: company.type
      })
    );
    Analytics.track('amba_tools_company_selected', {
      id: companyId,
      name: company.name
    });
  };

  useEffect(() => {
    if (demandPaths.includes(location.pathname) && buyer) {
      onSelectCompany(buyer);
      return;
    }

    if (supplyPaths.includes(location.pathname) && supplier) {
      onSelectCompany(supplier);
    }
  }, [buyer, location.pathname, supplier]);

  return {
    buyer,
    company,
    onSelectBuyer,
    onSelectCompany,
    onSelectSupplier,
    supplier
  };
};
