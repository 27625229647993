import mergeWith from 'lodash/mergeWith';
import deepmerge from 'deepmerge';
import dotProp from 'dot-prop-immutable';
import { normalize } from 'normalizr';
import humps from 'humps';
import * as schema from 'core/schema';

const overwriteMerge = (objValue, srcValue) => srcValue;

const customizer = (objValue, srcValue) => {
  if (Array.isArray(objValue)) {
    return srcValue;
  }

  return deepmerge(objValue, srcValue, { arrayMerge: overwriteMerge });
};

export default (state = {}, action) => {
  switch (action.type) {
    case 'ENTITIES_SET':
      return mergeWith({}, state, action.payload, customizer);
    case 'ENTITIES_DELETE':
      return dotProp.delete(state, `${action.payload.entityName}.${action.payload.entityId}`);
    case 'RESET':
      return {};
    default:
      return state;
  }
};

/**
 * action creator for updating / merging entities in the store
 * @param {*} data array or object, the payload containing data to be normalized
 * @param {*} entityType string, one of the schema in const/schema.js
 * @param {*} isArray boolean, if the data is an array of entities or a single entity object
 */
export const updateEntities =
  (data, entityType, isArray = false) =>
  (dispatch) => {
    const camelizedData = humps.camelizeKeys(data);
    const schemaType = isArray ? [schema[entityType]] : schema[entityType];
    const { entities } = normalize(camelizedData, schemaType);
    dispatch({ type: 'ENTITIES_SET', payload: entities });
  };

export const selectEntities = (state) => state.entities;
