import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Illustration, Text } from '@rooser/reef';
import { containerClasses, illustrationContainerClasses } from './classnames';
import useMaintenanceMode from '../../hooks/useMaintenanceMode/useMaintenanceMode';

const MaintenanceScreen = (): JSX.Element => {
  const { t } = useTranslation();
  useMaintenanceMode(document.referrer);

  return (
    <Container>
      <div className={containerClasses}>
        <div className={illustrationContainerClasses}>
          <Illustration name="upsideDownGreyscale" size="regular" />
        </div>

        <div>
          <Text id="content-boundary-header" variant="heading2" fontWeight="regular">
            {t('maintenancePage.title', 'The site is currently down for maintenance.')}
          </Text>
        </div>

        <div>
          <Text id="content-boundary-text" variant="text" fontWeight="regular">
            {t(
              'maintenancePage.subtitle',
              'We are making changes to our platform so that you can enjoy it even more!'
            )}
          </Text>
        </div>
      </div>
    </Container>
  );
};

export default MaintenanceScreen;
