import { combineReducers } from 'redux';

import app from 'features/app/reducer';
import auth from 'features/auth/reducer';
import company from 'features/company/reducer';
import datepicker from 'features/datepicker/reducer';
import marketplace from 'features/marketplace/reducer';
import modal from 'features/modal/reducer';
import orders from 'features/orders/reducer';
import priceRequests from 'features/pricerequests/reducer';
import sales from 'features/sales/reducer';
import entities from './entities';

const rootReducer = combineReducers({
  app,
  auth,
  company,
  datepicker,
  entities,
  marketplace,
  modal,
  orders,
  priceRequests,
  sales
});

export default rootReducer;
