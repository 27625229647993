import i18n from './i18n';
import { getSmartDay, isToday, isTomorrow } from './dates';

/**
 * Constants
 *
 * Note: many reflects what in App\Enums classes in Laravel
 */

/**
 *  -----------------------------------------------------------------
 *          Logistic and Fees
 *  -----------------------------------------------------------------
 */

export const Rooser = {
  comissionPercentage: 0,
  bankFeesPercentage: 0.0,
  insurancePercentage: 0.0,

  // Fishing method Exception
  farming: [9, 10, 11, 12],

  // AOP (certificate id)
  aop: 1,
  // Mussels ID (species id)
  mussel: 22
};

/**
 *  -----------------------------------------------------------------
 *          ADMINISTRATION constans
 *  -----------------------------------------------------------------
 */
export const companyType = {
  supplier: 0,
  buyer: 1,
  transport: 2
};

export const addressType = {
  headquarter: 0,
  billing: 1,
  dispatch: 2,
  delivery: 3,
  platform: 4,
  preparation: 5
};

export const occupationType = [
  { text: 'Director', value: 1 },
  { text: 'Manager', value: 2 },
  { text: 'Accountant', value: 3 },
  { text: 'Buyer/Seller', value: 4 },
  { text: 'Admin', value: 5 }
];

export const SaleType = Object.freeze({
  MARKETPLACE: 0,
  DEAL: 2,
  AMBASSADOR: 3
});

/**
 *  -----------------------------------------------------------------
 *          Countries and currencies
 *  -----------------------------------------------------------------
 */

export const languages = [
  { label: 'en', name: 'English' },
  { label: 'fr', name: 'Français' }
];

export const europeanCurrencies = {
  BGN: 'Bulgarian Lev',
  CHF: 'Swiss Franc',
  CZK: 'Czech Koruna',
  DKK: 'Danish Krone',
  EUR: 'Euro',
  GBP: 'British Pound Sterling',
  HRK: 'Croatian Kuna',
  HUF: 'Hungarian Forint',
  ISK: 'Icelandic Króna',
  PLN: 'Polish Zloty',
  RON: 'Romanian Leu',
  SEK: 'Swedish Krona'
  // USD: 'United States Dollar',
};

export const currencySymbols = {
  USD: '$', // US Dollar
  EUR: '€', // Euro
  CRC: '₡', // Costa Rican Colón
  GBP: '£', // British Pound Sterling
  ILS: '₪', // Israeli New Sheqel
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese Yen
  KRW: '₩', // South Korean Won
  NGN: '₦', // Nigerian Naira
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  THB: '฿', // Thai Baht
  UAH: '₴', // Ukrainian Hryvnia
  VND: '₫', // Vietnamese Dong
  BGN: 'Лв',
  CHF: 'CHf',
  CZK: 'Kč',
  DKK: 'Kr.',
  HRK: 'kn',
  HUF: 'Ft',
  ISK: 'Íkr',
  RON: 'lei',
  SEK: 'kr'
};

/**
 * This list needs to be synced with FishingArea.php list
 *
 * @see App\Enum\FishingArea.php
 */
export const originCountries = [
  {
    country: 'Albania',
    code: 'ALB',
    iso: 'AL',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇦🇱',
    union: false
  },
  {
    country: 'Austria',
    code: 'AUT',
    iso: 'AT',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇦🇹',
    union: false
  },
  {
    country: 'Bangladesh',
    code: 'BGD',
    iso: 'BD',
    timezone: 'Asia/Dhaka',
    utc: 6,
    flag: '🇧🇩',
    union: false
  },
  {
    country: 'Belgium',
    code: 'BEL',
    iso: 'BE',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇧🇪',
    union: false
  },
  {
    country: 'Bosnia and Herzegovina',
    code: 'BIH',
    iso: 'BA',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇧🇦',
    union: false
  },
  {
    country: 'Brazil',
    code: 'BRA',
    iso: 'BR',
    timezone: 'America/Bahia',
    utc: -3,
    flag: '🇧🇷',
    union: false
  },
  {
    country: 'British Virgin Islands',
    code: 'VGB',
    iso: 'GB',
    timezone: 'Atlantic/Bermuda',
    utc: -4,
    flag: '🇻🇬',
    union: false
  },
  {
    country: 'Bulgaria',
    code: 'BGR',
    iso: 'BG',
    timezone: 'Europe/Athens',
    utc: 2,
    flag: '🇧🇬',
    union: false
  },
  {
    country: 'Canada',
    code: 'CAN',
    iso: 'CA',
    timezone: 'America/Toronto',
    utc: -5,
    flag: '🇨🇦',
    union: false
  },
  {
    country: 'Chile',
    code: 'CHL',
    iso: 'CL',
    timezone: 'America/Santiago',
    utc: -3,
    flag: '🇨🇱',
    union: false
  },
  {
    country: 'Colombia',
    code: 'COL',
    iso: 'CO',
    timezone: 'America/Bogota',
    utc: -5,
    flag: '🇨🇴',
    union: false
  },
  {
    country: 'Cuba',
    code: 'CUB',
    iso: 'CU',
    timezone: 'America/Havana',
    utc: -5,
    flag: '🇨🇺',
    union: false
  },
  {
    country: 'Croatia',
    code: 'HRV',
    iso: 'HR',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇭🇷',
    union: false
  },
  {
    country: 'Cyprus',
    code: 'CYP',
    iso: 'CY',
    timezone: 'Europe/Athens',
    utc: 2,
    flag: '🇨🇾',
    union: false
  },
  {
    country: 'Czech Republic',
    code: 'CZE',
    iso: 'CZ',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇨🇿',
    union: false
  },
  {
    country: 'Denmark',
    code: 'DNK',
    iso: 'DK',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇩🇰',
    union: false
  },
  {
    country: 'Ecuador',
    code: 'ECU',
    iso: 'EC',
    timezone: 'America/Guayaquil',
    utc: -5,
    flag: '🇪🇨',
    union: false
  },
  {
    country: 'England',
    code: 'ENG',
    iso: 'GB',
    timezone: 'Europe/London',
    utc: 0,
    flag: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
    union: true
  },
  {
    country: 'Estonia',
    code: 'EST',
    iso: 'EE',
    timezone: 'Europe/Athens',
    utc: 2,
    flag: '🇪🇪',
    union: false
  },
  {
    country: 'Faroe Islands',
    code: 'FRO',
    iso: 'FO',
    timezone: 'Europe/London',
    utc: 0,
    flag: '🇫🇴',
    union: false
  },
  {
    country: 'Finland',
    code: 'FIN',
    iso: 'FI',
    timezone: 'Europe/Athens',
    utc: 2,
    flag: '🇫🇮',
    union: false
  },
  {
    country: 'France',
    code: 'FRA',
    iso: 'FR',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇫🇷',
    union: false
  },
  {
    country: 'Germany',
    code: 'DEU',
    iso: 'DE',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇩🇪',
    union: false
  },
  {
    country: 'Gibraltar',
    code: 'GIB',
    iso: 'GI',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇬🇮',
    union: false
  },
  {
    country: 'Greece',
    code: 'GRC',
    iso: 'GR',
    timezone: 'Europe/Athens',
    utc: 2,
    flag: '🇬🇷',
    union: false
  },
  {
    country: 'Hungary',
    code: 'HUN',
    iso: 'HU',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇭🇺',
    union: false
  },
  {
    country: 'Iceland',
    code: 'ISL',
    iso: 'IS',
    timezone: 'Europe/London',
    utc: 0,
    flag: '🇮🇸',
    union: false
  },
  {
    country: 'India',
    code: 'IND',
    iso: 'IN',
    timezone: 'Asia/Calcutta',
    utc: 5.5,
    flag: '🇮🇳',
    union: false
  },
  {
    country: 'Iran',
    code: 'IRN',
    iso: 'IR',
    timezone: 'Asia/Tehran',
    utc: 3.5,
    flag: '🇮🇷',
    union: false
  },
  {
    country: 'Ireland',
    code: 'IRL',
    iso: 'IE',
    timezone: 'Europe/London',
    utc: 0,
    flag: '🇮🇪',
    union: false
  },
  {
    country: 'Israel',
    code: 'ISR',
    iso: 'IL',
    timezone: 'Asia/Jerusalem',
    utc: 3,
    flag: '🇮🇱',
    union: false
  },
  {
    country: 'Italy',
    code: 'ITA',
    iso: 'IT',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇮🇹',
    union: false
  },
  {
    country: 'Ivory Coast',
    code: 'CIV',
    iso: 'CI',
    timezone: 'Africa/Abidjan',
    utc: 0,
    flag: '🇨🇮',
    union: false
  },
  {
    country: 'Japan',
    code: 'JPN',
    iso: 'JP',
    timezone: 'Asia/Tokyo',
    utc: 9,
    flag: '🇯🇵',
    union: false
  },
  {
    country: 'Republic of Korea',
    code: 'KOR',
    iso: 'KR',
    timezone: 'Asia/Seoul',
    utc: 9,
    flag: '🇰🇷',
    union: false
  },
  {
    country: 'Latvia',
    code: 'LVA',
    iso: 'LV',
    timezone: 'Europe/Athens',
    utc: 2,
    flag: '🇱🇻',
    union: false
  },
  {
    country: 'Lithuania',
    code: 'LTU',
    iso: 'LT',
    timezone: 'Europe/Athens',
    utc: 2,
    flag: '🇱🇹',
    union: false
  },
  {
    country: 'Luxembourg',
    code: 'LUX',
    iso: 'LU',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇱🇺',
    union: false
  },
  {
    country: 'Madagascar',
    code: 'MDG',
    iso: 'MG',
    timezone: 'Africa/Nairobi',
    utc: 3,
    flag: '🇲🇬',
    union: false
  },
  {
    country: 'Maldives',
    code: 'MDV',
    iso: 'MV',
    timezone: 'Indian/Maldives',
    utc: 5,
    flag: '🇲🇻',
    union: false
  },
  {
    country: 'Malta',
    code: 'MLT',
    iso: 'MT',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇲🇹',
    union: false
  },
  {
    country: 'Mauritania',
    code: 'MRT',
    iso: 'MR',
    timezone: 'Africa/Abidjan',
    utc: 0,
    flag: '🇲🇷',
    union: false
  },
  {
    country: 'Mauritius',
    code: 'MUS',
    iso: 'MU',
    timezone: 'Indian/Mauritius',
    utc: 4,
    flag: '🇲🇺',
    union: false
  },
  {
    country: 'Moldova',
    code: 'MDA',
    iso: 'MD',
    timezone: 'Europe/Athens',
    utc: 2,
    flag: '🇲🇩',
    union: false
  },
  {
    country: 'Montenegro',
    code: 'MNE',
    iso: 'ME',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇲🇪',
    union: false
  },
  {
    country: 'Morocco',
    code: 'MAR',
    iso: 'MA',
    timezone: 'Africa/Casablanca',
    utc: 0,
    flag: '🇲🇦',
    union: false
  },
  {
    country: 'Netherlands',
    code: 'NLD',
    iso: 'NL',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇳🇱',
    union: false
  },
  {
    country: 'Nicaragua',
    code: 'NIC',
    iso: 'NI',
    timezone: 'America/Managua',
    utc: -6,
    flag: '🇳🇮',
    union: false
  },
  {
    country: 'Nigeria',
    code: 'NGA',
    iso: 'NG',
    timezone: 'Africa/Lagos',
    utc: 1,
    flag: '🇳🇬',
    union: false
  },
  {
    country: 'North Macedonia',
    code: 'MKD',
    iso: 'MK',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇲🇰',
    union: false
  },
  {
    country: 'Northern Ireland',
    code: 'NIR',
    iso: 'GB',
    timezone: 'Europe/London',
    utc: 0,
    flag: '🇬🇧',
    union: true
  },
  {
    country: 'Norway',
    code: 'NOR',
    iso: 'NO',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇳🇴',
    union: false
  },
  {
    country: 'Peru',
    code: 'PER',
    iso: 'PE',
    timezone: 'America/Lima',
    utc: -5,
    flag: '🇵🇪',
    union: false
  },
  {
    country: 'Poland',
    code: 'POL',
    iso: 'PL',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇵🇱',
    union: false
  },
  {
    country: 'Portugal',
    code: 'PRT',
    iso: 'PT',
    timezone: 'Europe/London',
    utc: 0,
    flag: '🇵🇹',
    union: false
  },
  {
    country: 'Romania',
    code: 'ROU',
    iso: 'RO',
    timezone: 'Europe/Athens',
    utc: 2,
    flag: '🇷🇴',
    union: false
  },
  {
    country: 'Scotland',
    code: 'SCO',
    iso: 'GB',
    timezone: 'Europe/London',
    utc: 0,
    flag: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
    union: true
  },
  {
    country: 'Senegal',
    code: 'SEN',
    iso: 'SN',
    timezone: 'Africa/Abidjan',
    utc: 0,
    flag: '🇸🇳',
    union: false
  },
  {
    country: 'Serbia',
    code: 'SRB',
    iso: 'RS',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇷🇸',
    union: false
  },
  {
    country: 'Seychelles',
    code: 'SYC',
    iso: 'SC',
    timezone: 'Indian/Mahe',
    utc: 4,
    flag: '🇸🇨',
    union: false
  },
  {
    country: 'Slovakia',
    code: 'SVK',
    iso: 'SK',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇸🇰',
    union: false
  },
  {
    country: 'Slovenia',
    code: 'SVN',
    iso: 'SI',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇸🇮',
    union: false
  },
  {
    country: 'South Africa',
    code: 'ZAF',
    iso: 'ZA',
    timezone: 'Africa/Johannesburg',
    utc: 2,
    flag: '🇿🇦',
    union: false
  },
  {
    country: 'Spain',
    code: 'ESP',
    iso: 'ES',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇪🇸',
    union: false
  },
  {
    country: 'Sri Lanka',
    code: 'LKA',
    iso: 'LK',
    timezone: 'Indian/Standard',
    utc: 5,
    flag: '🇱🇰',
    union: false
  },
  {
    country: 'Sweden',
    code: 'SWE',
    iso: 'SE',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇸🇪',
    union: false
  },
  {
    country: 'Switzerland',
    code: 'CHE',
    iso: 'CH',
    timezone: 'Europe/Paris',
    utc: 1,
    flag: '🇨🇭',
    union: false
  },
  {
    country: 'Thailand',
    code: 'THA',
    iso: 'TH',
    timezone: 'Asia/Bangkok',
    utc: 7,
    flag: '🇹🇭',
    union: false
  },
  {
    country: 'Tunisia',
    code: 'TUN',
    iso: 'TN',
    timezone: 'Africa/Tunis',
    utc: 1,
    flag: '🇹🇳',
    union: false
  },
  {
    country: 'Turkey',
    code: 'TUR',
    iso: 'TR',
    timezone: 'Europe/Istanbul',
    utc: 3,
    flag: '🇹🇷',
    union: false
  },
  {
    country: 'Ukraine',
    code: 'UKR',
    iso: 'UA',
    timezone: 'Europe/Moscow',
    utc: 3,
    flag: '🇺🇦',
    union: false
  },
  {
    country: 'United States of America',
    code: 'USA',
    iso: 'US',
    timezone: 'America/New_York',
    utc: -5,
    flag: '🇺🇸',
    union: false
  },
  {
    country: 'Venezuela',
    code: 'VEN',
    iso: 'VE',
    timezone: 'America/Caracas',
    utc: -4,
    flag: '🇻🇪',
    union: false
  },
  {
    country: 'Vietnam',
    code: 'VNM',
    iso: 'VN',
    timezone: 'Asia/Saigon',
    utc: 7,
    flag: '🇻🇳',
    union: false
  },
  {
    country: 'Wales',
    code: 'WAL',
    iso: 'GB',
    timezone: 'Europe/London',
    utc: 0,
    flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
    union: true
  }
];

export const euCountryCodes = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GR',
  'HU',
  'HR',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK'
];

export const isCountryInEu = (isoCode) => euCountryCodes.includes(isoCode);

export const getCountryNameFromCode = (countryCode) => {
  const country = originCountries.find((c) => c.code === countryCode);
  return country ? country.country : countryCode;
};

/**
 *  -----------------------------------------------------------------
 *          Product certificates
 *  -----------------------------------------------------------------
 */
export const getLogoWidth = (certificateId) => {
  const widths = {
    1: 25,
    2: 25,
    3: 25,
    4: 35,
    5: 35,
    6: 32,
    7: 32,
    8: 36
  };

  return widths[certificateId] ? widths[certificateId] : 31;
};

/**
 *  -----------------------------------------------------------------
 *          Issues and Quality
 *  -----------------------------------------------------------------
 */
export const issuesTypes = [
  {
    text: 'quality',
    value: 1
  },
  {
    text: 'grade',
    value: 2
  },
  {
    text: 'volume',
    value: 3
  }
];

export const issueType = {
  1: 'Quality',
  2: 'Grade',
  3: 'Volume'
};

/**
 *  -----------------------------------------------------------------
 *          SALES
 *  -----------------------------------------------------------------
 */
export const saleDispatchStates = {
  new: 0,
  acknowledged: 1,
  complete: 2,
  acknowledgedCancellation: 3
};

export const dispatchStateUIs = [
  {
    value: saleDispatchStates.new,
    iconName: 'plus-l',
    toolTipContent: i18n.t('dispatch.clickToAknowledge', 'Click to acknowledge'),
    color: 'primary'
  },
  {
    value: saleDispatchStates.acknowledged,
    iconName: 'check-l',
    toolTipContent: i18n.t('dispatch.markAsComplete', 'Click to mark as complete'),
    color: 'twilight50'
  },
  {
    value: saleDispatchStates.complete,
    iconName: 'check-l',
    toolTipContent: i18n.t('dispatch.revertProgress', 'Click to revert progress'),
    color: 'success'
  },
  {
    value: saleDispatchStates.acknowledgedCancellation,
    iconName: 'minus-s',
    toolTipContent: i18n.t('dispatch.cancelledSale', 'This sale has been cancelled'),
    color: 'twilight20'
  }
];

export const getDispatchStateFromSale = ({ dispatchState, deletedAt }) =>
  dispatchStateUIs.find(({ value }) => {
    if (deletedAt) return value === saleDispatchStates.acknowledgedCancellation;
    return value === dispatchState;
  });

export type TagTypes = 'active' | 'success' | 'error' | 'accent' | 'neutral';

export type DispatchStatus = {
  value: number;
  text: string;
  tagType: TagTypes;
};

export const dispatchStatuses = {
  new: 0,
  accepted: 1,
  confirmed: 2,
  cancelled: 3
};

export const dispathStatusTags: DispatchStatus[] = [
  {
    value: dispatchStatuses.new,
    text: i18n.t('dispatch.statusTags.new', 'New'),
    tagType: 'accent' as TagTypes
  },
  {
    value: dispatchStatuses.accepted,
    text: i18n.t('dispatch.statusTags.accepted', 'Accepted'),
    tagType: 'active' as TagTypes
  },
  {
    value: dispatchStatuses.confirmed,
    text: i18n.t('dispatch.statusTags.confirmed', 'Confirmed'),
    tagType: 'success' as TagTypes
  },
  {
    value: dispatchStatuses.cancelled,
    text: i18n.t('dispatch.statusTags.cancelled', 'Cancelled'),
    tagType: 'error' as TagTypes
  }
];

export const getDispatchStatusTagFromSale = ({
  dispatchState,
  deletedAt
}: {
  dispatchState: DispatchStatus['value'];
  deletedAt?: string;
}): DispatchStatus =>
  dispathStatusTags.find(({ value }) => {
    if (deletedAt) return value === dispatchStatuses.cancelled;
    return value === dispatchState;
  });

/**
 *  -----------------------------------------------------------------
 *          ROUTES
 *  -----------------------------------------------------------------
 */
export const datepickerRoutes = ['/dispatch'];

/**
 * DELIVERY DATE FILTERS
 */
export const labelForDate = (date) => {
  if (isToday(date)) {
    return i18n.t('marketplace.searchDeliveryDate.today', 'Delivery for today');
  }
  if (isTomorrow(date)) {
    return i18n.t('marketplace.searchDeliveryDate.tomorrow', 'Delivery for tomorrow');
  }
  return i18n.t('marketplace.searchDeliveryDate.DayDate', 'Delivery for {{ date }}', {
    date: getSmartDay(date)
  });
};

export const optionForAll = {
  type: 'all',
  value: '',
  label: i18n.t('marketplace.searchDeliveryDate.placeholder', 'Any delivery date')
};

/**
 *  -----------------------------------------------------------------
 *          BULK DISCOUNTS
 *  -----------------------------------------------------------------
 */

export const bulkDiscountThresholds = [
  { requiredWeight: 100, discountPercent: 1.5 },
  { requiredWeight: 200, discountPercent: 2.5 },
  { requiredWeight: 300, discountPercent: 3.5 }
];

export const demandPaths = ['/marketplace', '/orders', '/invoices', '/live-market-buyers'];
export const supplyPaths = ['/offers', '/sku-offers', '/dispatch', '/invoices', '/live-market'];

export const datepickerLinkingOptionsCount = {
  internalUsers: 7,
  externalUsers: 2
};
