export const MARKET_OFFERS_SET = 'Market: set offers';
export const MARKET_OFFERS_RESET = 'Market: reset the offers';
export const MARKET_SET_FILTERS = 'Market: Set filters';
export const MARKET_CLEAR_SEARCH = 'Market: Clear search';
export const MARKET_SET_LOADING = 'Market: Sets loading state';
export const MARKET_OFFERS_ADD = 'Market: add offer to the list';
export const MARKET_OFFERS_REMOVE = 'Market: remove offer (goes offline, sold or canceled)';
export const MARKET_SALES_ADD = 'Market: add new sale';
export const MARKET_ROUTES = 'Market: set routes';
export const MARKET_TRACK_SEARCH_RESULTS = 'Market: track search results';
export const MARKET_LINKED_SUPPLIERS = 'Market: set linked suppliers';
export const MARKET_SORT_BY = 'Market: update sorting state';
export const MARKET_SET_IS_PRODUCT_REQUEST_OPEN =
  'Market: set the open state of the product request modal';
export const MARKET_SET_PRODUCT_REQUEST_DELIVERY_DATE =
  'Market: set the delivery date for the product request modal';
export const MARKET_SET_REAL_TIME_NEW_OFFER_IDS = 'Market: set new offers that come in real-time';
export const SWITCH_LANGUAGE = 'Market: language was switched';
export const MARKET_SET_IS_INITIAL_FETCH = 'Market: set isInitialFetch state';
export const MARKET_CLEAR_ALL_FITLERS = 'Market: clear all filters, delivery date, and sort';

// Quick-fix to allow filter details to be included in analytic events.
// TODO: remove the usage of both usState and Redux for this data.
export const MARKET_SET_DELIVERY_DATE_FILTER = 'Market: set delivery date filter';

export const MARKET_SET_CURATED_OFFERS = 'Market: set curated offers';
export const MARKET_ADD_CURATED_OFFER = 'Market: add curated offer id to state';
