import * as ActionTypes from './actionTypes';

export const initialState = {
  loading: false,
  priceRequests: [],
  acceptedPriceRequest: null,
  declinedPriceRequest: null,
  showPanel: true
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.PRICE_REQUESTS_LOADING:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.PRICE_REQUESTS_SET_PRICE_REQUESTS:
      return {
        ...state,
        priceRequests: payload,
        loading: false
      };
    case ActionTypes.PRICE_REQUESTS_SET_ACCEPTED_PRICE_REQUEST:
      return {
        ...state,
        acceptedPriceRequest: payload
      };
    case ActionTypes.PRICE_REQUESTS_SET_DECLINED_PRICE_REQUEST:
      return {
        ...state,
        declinedPriceRequest: payload
      };
    case ActionTypes.PRICE_REQUESTS_ADD_NEW_PRICE_REQUEST:
      return {
        ...state,
        priceRequests: [...new Set(state.priceRequests.concat(payload))]
      };
    case ActionTypes.PRICE_REQUESTS_SHOW_PANEL:
      return { ...state, showPanel: true };
    case ActionTypes.PRICE_REQUESTS_HIDE_PANEL:
      return { ...state, showPanel: false };
    default:
      return { ...state };
  }
};
