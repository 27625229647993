/**
 * Rooser Business Logic
 *
 * All rules and exceptions that define business rules applying on Rooser
 */
class Rooser {
  /**
   * Minimum weight per order allowed by Rooser globally
   */
  static minimumKgPerOrder = 1;

  /**
   * Rooser's comission (percentage)
   */
  static comissionPercentage = 3;

  /**
   * Bank fees (Foreign exchange charge)
   * As percentage
   */
  static bankFeesPercentage = 0;

  /**
   * Cost of insuring each transactions on Rooser
   * As percentage
   */
  static insurancePercentage = 0;

  /**
   * The IDS of FishingMethod records that qualifies as farmed fish
   */
  static farming = [9, 10, 11, 12];

  /**
   * The IDS of Natures records that qualifies as alive product
   */
  static aliveNatureIds = [2, 5, 6];

  /**
   * ID of the Protected Designation of Origin quality mark
   * PDO (AOP in French) has specific label requirements
   */
  static aop = 1;

  /**
   * The Mussels are the only species that can have the PDO mark
   * Therefore we have specific mentions on label for them.
   */
  static mussel = 22;

  /**
   * Minimum volume for an offer to be sold as a whole
   */
  static fullTruckMinimum = 5000;

  /**
   * The quantity increment/decrement when clicking +/- to adjust quantity
   * For the suppliers
   */
  static quantityIncrement = 5;

  /**
   * The selling price increment/decrement when clicking +/- to adjust quantity
   * For the suppliers
   */
  static priceIncrement = 0.05;

  /**
   * Supported languages
   */
  static supportedLanguages = ['fr', 'en'];

  /**
   * Calculate the final price from supplier offer selling price to customer's buying price
   * Adding all costs: Rooser comission, transports, quality, bank, insurance, etc
   *
   * @param offer The offer being purchased
   * @param buyerCompany The company buying the offer
   * @param sellingPriceOverride Optional selling price. Will use the selling price of the offer otherwise.
   */
  static calculateCustomerPrice = (offer, buyerCompany, sellingPriceOverride = null) => {
    const sellingPrice = sellingPriceOverride || offer?.sellingPrice || 0;
    const transportCost = offer?.route?.price || 0;
    const extraCost = offer?.route?.extraCost || 0;
    const insurance = buyerCompany.insuranceRate || Rooser.insurancePercentage;
    const rebate = buyerCompany.rebate;
    const commission = this.getCommission(offer.productCommission, buyerCompany.customComission);

    let price = sellingPrice + transportCost + extraCost;

    if (buyerCompany.rooserStock) {
      return price;
    }

    price /= 1 - Rooser.bankFeesPercentage / 100;
    price /= 1 - insurance / 100;
    price /= 1 - commission / 100;

    return rebate ? price / (1 - rebate / 100) : price;
  };

  /**
   * Calculate the supplier's ex-works price from customer's buying price
   * Taking into account all costs: Rooser comission, transports, quality, bank, insurance, etc
   * Note that the finalPrice should already be converted to per kg if offer is using pot
   *
   * @param finalPrice The price given to the customer
   * @param offer with productCommission, route and supplier company
   * @param buyerCompany the customer company
   */
  static calculateSupplierPrice = (finalPrice, offer, buyerCompany = {}) => {
    let customerPrice = finalPrice;

    // take out rebate:
    if (buyerCompany.rebate > 0) {
      customerPrice = finalPrice * (1 - buyerCompany.rebate / 100);
    }

    // take out commission:
    const commissionPercent = this.getCommission(
      offer.productCommission,
      buyerCompany.customComission
    );
    if (commissionPercent > 0) {
      customerPrice *= 1 - commissionPercent / 100;
    }

    // take out insurance:
    const insurancePercent = buyerCompany.insuranceRate || Rooser.insurancePercentage;
    if (insurancePercent > 0) {
      customerPrice *= 1 - insurancePercent / 100;
    }

    // take out banking fees:
    const bankingFees = Rooser.bankFeesPercentage;
    if (bankingFees > 0) {
      customerPrice /= 1 - bankingFees / 100;
    }

    // Use data within `logisticInfo` parameter, if available.
    if (offer.logisticInfo) {
      customerPrice -=
        offer.logisticInfo.aggregationCost +
        offer.logisticInfo.extraCost +
        offer.logisticInfo.handlingCost +
        offer.logisticInfo.transitCost;

      return customerPrice;
    }

    // take out quality cost:
    customerPrice -= offer.route.extraCost || 0;

    // take out transportCost
    const transportCost = offer.route.price;
    if (transportCost > 0) {
      customerPrice -= transportCost;
    }

    return customerPrice;
  };

  static getRoundedSupplierPrice = (finalPrice, offer, buyerCompany) =>
    Math.round(this.calculateSupplierPrice(finalPrice, offer, buyerCompany) * 100) / 100;

  /**
   * Decide what commission to use between the ff options:
   * (1) offer's product commission *if greater than buyer commission*
   * (2) buyer's custom commission
   * (3) default Rooser commission
   *
   * @param productCommission The offer's product commission
   * @param customCommission The buyer company's custom commission
   */
  static getCommission = (productCommission, customComission) => {
    if (!!productCommission && productCommission >= customComission) {
      return productCommission;
    }
    return customComission || this.comissionPercentage;
  };

  /**
   * Get the transport cost matching the weight ordered
   *  Price can
   *
   * @param totalKg The total weight in kg
   * @param route The route object containing prices ranges
   */
  static getTransportCost = (totalKg, route) => {
    if (!route) {
      return 0;
    }
    return route.price;
  };

  /**
   * Gets the country and country fishing area string for a given offer.
   *
   * @param offer The offer that you want to retrieve the country and country fishing area string for.
   */
  static getFishingCountryString = (offer) => {
    let country = '';
    let countryFishingArea = '';

    if (offer && offer.product) {
      const isFarmedProduct =
        offer.product.fishingMethod &&
        Rooser.farming.indexOf(offer.product.fishingMethod.id) !== -1;
      country = isFarmedProduct ? offer.product.harvestCountry : offer.product.country;
      if (!isFarmedProduct && offer.product.fishingArea) {
        countryFishingArea = country.concat(`/${offer.product.fishingArea.code}`);
      } else {
        countryFishingArea = country;
      }
    }

    return { country, countryFishingArea };
  };

  /**
   * Rounds a given price to the nearest five cents.
   *
   * @param {number} price
   * @returns {number}
   */
  static roundToNearestFiveCents = (price) => Math.round(price * 20) / 20;
}

export default Rooser;
