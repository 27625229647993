import * as ActionTypes from './actionTypes';

export const initialState = {
  filters: {
    dispatchDate: null,
    orderNo: null
  },
  sales: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SALES_SET_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...payload }
      };
    case ActionTypes.SALES_SET_SALES:
      return {
        ...state,
        sales: payload
      };
    case ActionTypes.SALES_ADD_SALE:
      return {
        ...state,
        sales: [...new Set(state.sales.concat(payload))]
      };
    case ActionTypes.SALES_REMOVE_SALE:
      return {
        ...state,
        sales: state.sales.filter((saleId) => saleId !== payload)
      };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};
