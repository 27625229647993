export const APP_DATA_SPECIES = 'App data: species';
export const APP_DATA = 'App data: all product data last fetch';
export const APP_DATA_PRODUCT_REFERENCES = 'App references: all product refs';
export const APP_DATA_TYPES = 'App data: types';
export const APP_DATA_VARIATIONS = 'App data: variations';
export const APP_DATA_GRADES = 'App data: grades';
export const APP_DATA_PACKINGS = 'App data: packings';
export const APP_DATA_FISHING_AREAS = 'App data: fishing areas';
export const APP_DATA_FISHING_METHODS = 'App data: fishing methods';
export const APP_DATA_CERTIFICATES = 'App data: certificates';
export const APP_DATA_CERTIFICATIONS = 'App data: certifications';
export const APP_DATA_TRANSLATION_LOADING = 'App data: translation loading';
export const APP_OVERLAY = 'App: toggle the overlay component';
export const SWITCH_LANGUAGE = 'App data: switch language';
