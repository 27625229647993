import React from 'react';

export enum ProductRequestSource {
  filtersPanel = 'Filters Panel',
  marketplace = 'Marketplace',
  noResultsPlaceholder = 'No Results Placeholder',
}

export interface UseProductRequestBanner {
  productRequestSource: ProductRequestSource;
  setProductRequestSource: (value: ProductRequestSource) => void;
}

export const useProductRequestBanner = (): UseProductRequestBanner => {
  const [productRequestSource, setProductRequestSource] = React.useState<ProductRequestSource>(null);
  return { productRequestSource, setProductRequestSource };
};
