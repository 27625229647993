import * as ActionTypes from './actionTypes';

export const initialState = {
  // App Data => Dropdown data lists (cached)
  fishingAreas: [],
  fishingMethods: [],
  certifications: [],
  certificates: [],
  species: [],
  types: [],
  variations: [],
  grades: [],
  packings: [],
  productReferences: {},
  lastFetch: {
    productData: new Date(),
    productReferences: new Date(),
    certifications: new Date(),
    certificates: new Date(),
    species: new Date(),
    types: new Date(),
    fishingAreas: new Date(),
    fishingMethods: new Date(),
    packings: new Date()
  },
  translationLoading: false,
  overlayShowing: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SWITCH_LANGUAGE:
      return {
        ...state,
        ...initialState,
        productReferences: state.productReferences,
        lastFetch: {
          ...state.lastFetch,
          productReferences: state.lastFetch.productReferences
        }
      };
    case ActionTypes.APP_DATA:
      return {
        ...state,
        lastFetch: {
          ...state.lastFetch,
          productData: new Date()
        }
      };
    case ActionTypes.APP_DATA_PRODUCT_REFERENCES:
      return {
        ...state,
        productReferences: payload,
        lastFetch: {
          ...state.lastFetch,
          productReferences: new Date()
        }
      };
    case ActionTypes.APP_DATA_CERTIFICATIONS:
      return {
        ...state,
        certifications: payload,
        lastFetch: {
          ...state.lastFetch,
          certifications: new Date()
        }
      };
    case ActionTypes.APP_DATA_CERTIFICATES:
      return {
        ...state,
        certificates: payload,
        lastFetch: {
          ...state.lastFetch,
          certificates: new Date()
        }
      };
    case ActionTypes.APP_DATA_SPECIES:
      return {
        ...state,
        species: payload,
        lastFetch: {
          ...state.lastFetch,
          species: new Date()
        }
      };
    case ActionTypes.APP_DATA_TYPES:
      return {
        ...state,
        types: payload,
        lastFetch: {
          ...state.lastFetch,
          types: new Date()
        }
      };
    case ActionTypes.APP_DATA_GRADES:
      return {
        ...state,
        grades: payload,
        lastFetch: {
          ...state.lastFetch,
          grades: new Date()
        }
      };
    case ActionTypes.APP_DATA_VARIATIONS:
      return {
        ...state,
        variations: payload,
        lastFetch: {
          ...state.lastFetch,
          variations: new Date()
        }
      };
    case ActionTypes.APP_DATA_PACKINGS:
      return {
        ...state,
        packings: payload,
        lastFetch: {
          ...state.lastFetch,
          packings: new Date()
        }
      };
    case ActionTypes.APP_DATA_FISHING_METHODS:
      return {
        ...state,
        fishingMethods: payload,
        lastFetch: {
          ...state.lastFetch,
          fishingMethods: new Date()
        }
      };
    case ActionTypes.APP_DATA_FISHING_AREAS:
      return {
        ...state,
        fishingAreas: payload,
        lastFetch: {
          ...state.lastFetch,
          fishingAreas: new Date()
        }
      };
    case ActionTypes.APP_DATA_TRANSLATION_LOADING:
      return {
        ...state,
        translationLoading: payload
      };
    case ActionTypes.APP_OVERLAY:
      return { ...state, overlayShowing: payload };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};
