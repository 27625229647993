import { createSelector } from 'reselect';

const selectEntities = (state) => state.entities;
const selectSpeciesIds = (state) => state.app.species;
const selectTypeIds = (state) => state.app.types;
const selectVariationIds = (state) => state.app.variations;
const selectGradeIds = (state) => state.app.grades;
const selectPackingIds = (state) => state.app.packings;
const selectFishingMethodIds = (state) => state.app.fishingMethods;
const selectFishingAreaIds = (state) => state.app.fishingAreas;
const selectProps = (_, props) => props;

export const selectCertificates = (state) => state.app.certificates;
export const selectCertifications = (state) => state.app.certifications;
export const selectCompanyCurrency = (state) => state.company.company.currency;
export const selectCompanyType = (state) => state.company.company.type;
export const selectOverlayShowing = (state) => state.app.overlayShowing;
export const selectReferences = (state) => state.app.productReferences;
export const selectTranslationLoading = (state) => state.app.translationLoading;

export const selectSpecies = createSelector(
  [selectSpeciesIds, selectEntities],
  (speciesIds, entities) => {
    if (speciesIds) {
      const sortByName = (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      };
      return speciesIds.map((sid) => entities.species[sid]).sort(sortByName);
    }
    return [];
  }
);

export const selectTypes = createSelector(
  [selectTypeIds, selectEntities, selectProps, selectReferences],
  (typesId, entities, props, references) => {
    let options = [];
    if (typesId) {
      options = typesId.map((tid) => entities.types[tid]);
      // Filter options if species is selected
      if (
        references &&
        options.length > 0 &&
        props.species &&
        props.species.id &&
        references[props.species.id]
      ) {
        const refTypes = Object.keys(references[props.species.id]).map((k) => parseInt(k, 10));
        options = options.filter((t) => refTypes.indexOf(t.id) !== -1);
      }
    }
    return options;
  }
);

export const selectVariations = createSelector(
  [selectVariationIds, selectEntities, selectProps, selectReferences],
  (variationIds, entities, props, references) => {
    let options = [];
    if (variationIds) {
      options = variationIds.map((vid) => entities.variations[vid]);

      // Filter options if species & type are selected
      if (
        references &&
        options.length > 0 &&
        props.species &&
        props.species.id &&
        references[props.species.id] &&
        props.type &&
        props.type.id &&
        references[props.species.id][props.type.id]
      ) {
        const variations =
          references[props.species.id][props.type.id] &&
          Object.keys(references[props.species.id][props.type.id]);
        if (variations) {
          const refVariations = variations.map((k) => parseInt(k, 10));
          options = options.filter((v) => refVariations.indexOf(v.id) !== -1);
        }
      }
    }
    return options;
  }
);

export const selectGrades = createSelector(
  [selectGradeIds, selectEntities, selectProps, selectReferences],
  (gradeIds, entities, props, references) => {
    let options = [];
    if (gradeIds) {
      options = gradeIds.map((gid) => entities.grades[gid]);
      if (
        references &&
        options.length > 0 &&
        props &&
        props.species &&
        props.species.id &&
        props.type &&
        props.type.id &&
        props.variation &&
        props.variation.id &&
        references[props.species.id] &&
        references[props.species.id][props.type.id] &&
        references[props.species.id][props.type.id][props.variation.id]
      ) {
        const refGradeIds = references[props.species.id][props.type.id][props.variation.id].map(
          (o) => o.gradeId
        );
        options = options.filter((g) => refGradeIds.indexOf(g.id) !== -1);
      }
    }
    return options;
  }
);

export const selectPackings = createSelector(
  [selectPackingIds, selectEntities],
  (packingIds, entities) => {
    if (packingIds) {
      return packingIds.map((pid) => entities.packings[pid]);
    }
    return [];
  }
);

export const selectFishingMethods = createSelector(
  [selectFishingMethodIds, selectEntities],
  (fishingMethodsIds, entities) => {
    if (fishingMethodsIds) {
      return fishingMethodsIds.map((pid) => entities.fishingMethods[pid]);
    }
    return [];
  }
);

export const selectFishingAreas = createSelector(
  [selectFishingAreaIds, selectEntities],
  (areas, entities) => {
    if (areas) {
      return areas.map((aid) => entities.fishingAreas[aid]);
    }
    return [];
  }
);

// Deprecated - use makeSelectPackingOptions
export const selectPackingOptions = createSelector(
  [selectProps, selectPackings, selectReferences],
  (props, packings, references) => {
    let options = [];
    const { species, type, variation } = props;
    // Filter options if species & type & variation are selected
    if (
      references &&
      species &&
      references[species.id] &&
      type &&
      references[species.id][type.id] &&
      variation &&
      references[species.id][type.id][variation.id] &&
      packings.length > 0
    ) {
      const isCombinationPossible =
        references[species.id] &&
        references[species.id][type.id] &&
        references[species.id][type.id][variation.id];

      if (isCombinationPossible) {
        const refPackingIds = references[species.id][type.id][variation.id].map((o) => o.packingId);
        options = packings.filter((p) => refPackingIds.indexOf(p.id) !== -1);
      }
    } else {
      options = packings;
    }

    return options;
  }
);

export const makeSelectPackingOptions = () =>
  createSelector(
    [
      selectPackings,
      selectReferences,
      (_, { species }) => species,
      (_, { type }) => type,
      (_, { variation }) => variation
    ],
    (packings, references, species, type, variation) => {
      let options = [];
      // Filter options if species & type & variation are selected
      if (
        references &&
        species &&
        references[species.id] &&
        type &&
        references[species.id][type.id] &&
        variation &&
        references[species.id][type.id][variation.id] &&
        packings.length > 0
      ) {
        const isCombinationPossible =
          references[species.id] &&
          references[species.id][type.id] &&
          references[species.id][type.id][variation.id];

        if (isCombinationPossible) {
          const refPackingIds = references[species.id][type.id][variation.id].map(
            (o) => o.packingId
          );
          options = packings.filter((p) => refPackingIds.indexOf(p.id) !== -1);
        }
      } else {
        options = packings;
      }

      return options;
    }
  );

export const selectDefaultPacking = createSelector(
  [selectProps, selectPackingOptions],
  (props, options) => {
    const { defaultPackingId, defaultValue } = props;
    const defaultPackingIndex = defaultPackingId
      ? options.findIndex((p) => p.id === defaultPackingId)
      : null;
    return defaultPackingIndex !== -1 ? options[defaultPackingIndex] : defaultValue;
  }
);
