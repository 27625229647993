import ReactGA from 'react-ga4';

/**
 * Abstraction layer for product analytics
 *
 */
class ReactGAWrapper {
  /**
   * Initialize Google Analytics using the provided token
   * @param {string} trackingID
   */
  constructor(trackingID) {
    ReactGA.initialize(trackingID, {
      debug: false,
      titleCase: false
    });
  }

  /**
   * Page view (Google Analytics only)
   * @param {*} pathName
   * @param {*} isModal
   */
  page = (pathName, { isModal }) => {
    if (isModal) {
      ReactGA.send({ hitType: 'pageview', page: `modal/${pathName}` });
    } else {
      ReactGA.send({ hitType: 'pageview', page: pathName });
    }
  };

  /**
   * Event props (Google Analytics only)
   * @param {action, category, [value], [label]} eventProps
   */
  event = (eventProps) => {
    ReactGA.event(eventProps);
  };

  /**
   * Set the values of custom dimensions (Google Analytics only)
   * @param {object} setProps
   */
  set = (setProps) => {
    ReactGA.set(setProps);
  };
}

export default ReactGAWrapper;
