import React, { Suspense } from 'react';

import 'core/wdyr';

import './index.css';
import { createBrowserHistory } from 'history';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import { Integrations as SentryTracingIntegrations } from '@sentry/tracing';
import { ReactQueryDevtools } from 'react-query/devtools';

import 'core/i18n';
import 'core/proto';
import { store, persistor } from 'core/configStore.ts';
import QueryClientProvider from 'features/app/components/QueryClientProvider/QueryClientProvider';
import AppLoading from 'features/app/components/AppLoading/AppLoading';
import { authCheck } from 'features/auth/actions';
import AppRoot from './AppRoot';

const history = createBrowserHistory();

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
    ignoreErrors: [
      'ChunkLoadError', // resolved in https://rooser.atlassian.net/browse/RS-3842
      'ResizeObserver loop limit exceeded' // https://rooser.atlassian.net/browse/RM-1614
    ],
    integrations: [
      new SentryTracingIntegrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
      })
    ],
    maxBreadcrumbs: 50,
    normalizeDepth: 10,
    release: `${process.env.SENTRY_PROJECT}@${process.env.AWS_JOB_ID}`, // TODO: Change this to a value set by CI pipeline.
    tracesSampleRate: Number(process.env.SENTRY_SAMPLING_RATE)
  });
}

store.dispatch(authCheck());

const Root = () => (
  <Suspense fallback={<AppLoading />}>
    <Provider store={store}>
      <QueryClientProvider>
        <PersistGate loading={null} persistor={persistor}>
          <AppRoot history={history} />
        </PersistGate>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  </Suspense>
);

render(<Root />, document.getElementById('react-root'));

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept();
}

if (window.Cypress) {
  window.store = store;
}
