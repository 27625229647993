import React from 'react';
import { LocalStorageFlag, LocalStorageFlagContext } from 'crypsis/LocalStorageFlagContext';

export function useLocalStorageFlag(): LocalStorageFlag {
  const value = React.useContext(LocalStorageFlagContext);

  if (value === undefined) {
    throw new Error('`useLocalStorageFlag` must be used within a `LocalStorageFlagProvider`');
  }

  return value;
}
