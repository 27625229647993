import { useSelector } from 'react-redux';

export const useUserLanguage = () => {
  const language = useSelector((state) => state.auth.user.language);
  return { language };
};

export const useCurrentUser = () => {
  const user = useSelector((state) => state.auth.user);
  return { user };
};

export const useIsAuthenticated = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return { isAuthenticated };
};
