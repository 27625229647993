import React from 'react';

export function StringFlagValue({ value, onChange }: { value: string, onChange: (newValue: string) => void }): React.JSX.Element {
  const [isEditing, setEditing] = React.useState(false);
  const [editingValue, setEditingValue] = React.useState(value);

  if (!isEditing) {
    return <b><button type="button" onClick={() => setEditing(true)}>{value}</button></b>;
  }

  return (
    <input
      autoFocus
      value={editingValue}
      onChange={(event) => setEditingValue(event.currentTarget.value)}
      onBlur={() => {
        setEditing(false);
        setEditingValue(value);
      }}
      onFocus={(event) => event.target.select()}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          setEditing(false);
          setEditingValue(value);
        }
        if (event.key === 'Enter') {
          setEditing(false);
          onChange(editingValue);
        }

      }}
    />
  );
}
