import axios from 'axios';
import humps from 'humps';
import { getRoles, getToken, logout, storeToken } from './auth';

/**
 * Send CSRF token to Laravel
 */
// const token = document.head.querySelector('meta[name="csrf-token"]') || global.mockToken;
axios.defaults.baseURL = process.env.APP_URL;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// const pending = {};
// // Cancel token to cancel requests
// const CancelToken = axios.CancelToken;
// const removePending = (config, f) => {
//   // make sure the url is same for both request and response
//   if (config && config.url) {
//     const url = config.url.replace(config.baseURL, '/');
//     // stringify whole RESTful request with URL params
//     const flagUrl = `${url}&${config.method}&${JSON.stringify(config.params)}`;
//     if (flagUrl in pending) {
//       if (f) {
//         f(); // abort the request
//       } else {
//         delete pending[flagUrl];
//       }
//     } else if (f) {
//       pending[flagUrl] = f; // store the cancel function
//     }
//   }
// };

/**
 * Intercept request
 *
 * - Add auth token to it
 * - Decamelize keys of data sent to backend
 */
axios.interceptors.request.use(
  (request) => {
    // you can apply cancel token to all or specific requests
    // e.g. except config.method == 'options'
    // request.cancelToken = new CancelToken((c) => {
    //   if (request.method === 'post') {
    //     removePending(request, c);
    //   }
    // });

    if (
      request.method === 'post' ||
      request.method === 'POST' ||
      request.method === 'put' ||
      request.method === 'PUT' ||
      request.method === 'patch' ||
      request.method === 'PATCH'
    ) {
      if (request.headers['Content-Type'] !== 'multipart/form-data') {
        request.data = humps.decamelizeKeys(request.data);
      }
    }

    const isSupplyAmba = getRoles().includes('supplyAmba');
    const isDemandAmba = getRoles().includes('demandAmba');
    const selectedCompany = localStorage.getItem('company');

    if ((isSupplyAmba || isDemandAmba) && selectedCompany) {
      const companyId = JSON.parse(selectedCompany)?.id;
      request.headers['Company-Id'] = companyId;
    } else {
      delete request.headers['Company-id'];
    }

    request.headers.Authorization = `Bearer ${getToken()}`;

    const { hostname } = window.location;

    return request;
  },
  (error) => Promise.reject(error)
);

/**
 * Intercept response
 *
 * - Camelize keys of data received from backend
 */
axios.interceptors.response.use(
  (response) => {
    if (
      (response.headers['content-type'] &&
        response.headers['content-type'].match(/application\/json/g)) ||
      (response.headers['Content-Type'] &&
        response.headers['Content-Type'].match(/application\/json/g))
    ) {
      response.data = humps.camelizeKeys(response.data);
    }

    // Store new token if refreshed
    if (response.headers.authorization) {
      storeToken(response.headers.authorization.replace('Bearer ', ''));
    }

    if (!response.data) {
      response.data = [];
    }

    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        if (!window.location.href.match('/login') && !window.location.href.match('/logout')) {
          logout();
        }
        if (!error.request.responseURL.match('/login')) {
          return new Promise(() => {
            console.warn(error.message);
          });
        }
      }

      if (error.response.status === 503 && window.location.pathname !== '/maintenance') {
        window.location.href = '/maintenance';
      }
    }

    return Promise.reject(error);
  }
);

/**
 * Clear the pending requests
 */
// axios.clear = () => {
//   Object.keys(pending).forEach((e) => {
//     if (pending[e]) {
//       pending[e]();
//       delete pending[e];
//     }
//   });
// };

export default axios;
