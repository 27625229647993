import dayjs from 'dayjs';
import classNames from 'classnames';
import { companyType } from 'core/const';
import { sortOffers, sortProducts, isSkreiCod, SKREI } from 'core/sort';
import i18n from 'core/i18n';
import Rooser from 'core/logic/logic';
import WeightLogic from 'core/weight/weight';
import { roundTo2dp } from './roundings/roundings';

/**
 * Get the classNames causing the blinking animation when an offer update
 * @param {*} offer The offer object
 * @param {string|Object} extraClasses An object or string corresponding to any extra class name require
 */
export const offerUpdateEffectClassNames = (offer, extraClasses) => {
  const publishedClass = offer.published ? '' : 'disabled';
  const rowUpdatedAtDiff = dayjs(offer.updatedAt).diff(dayjs(), 'seconds');
  let udpatedAtClass = null;
  if (offer.published && rowUpdatedAtDiff > -35) udpatedAtClass = 'didJustUpdate';
  if (offer.published && rowUpdatedAtDiff > -210 && rowUpdatedAtDiff < -35)
    udpatedAtClass = 'updatedAWhileAgo';
  if (offer.published && rowUpdatedAtDiff > -365 && rowUpdatedAtDiff < -210)
    udpatedAtClass = 'updatedALongAgo';
  return classNames(udpatedAtClass, publishedClass, extraClasses);
};

/**
 * Group an array of data by keys made thanks to the accessor
 *
 * @param {*} data The array of data
 * @param {*} accessor The property accessor used to form the key
 *
 * @return array Array of array grouped by keys
 */
export const groupData = (data = [], accessor = '') => {
  if (data.length === 0 || !accessor) {
    return { grouped: {}, list: [] };
  }

  const grouped = data.reduce((acc, item) => {
    const groups = acc;
    if (!item) {
      return groups;
    }

    let groupBy = accessor;
    const keys = accessor.split('.');
    if (keys.length > 0) {
      groupBy = keys.reduce((acc, key) => acc[key], item);
    }

    groups[groupBy] = groups[groupBy] || [];
    groups[groupBy].push(item);
    return groups;
  }, {});

  return {
    grouped,
    list: Object.keys(grouped)
  };
};

/**
 * Dispatch a scrollTo Event
 * @param {*} htmlId Object with an HTML id
 */
export const scrollToId = (htmlId) =>
  window.dispatchEvent(
    new CustomEvent('scrollTo', {
      detail: {
        id: htmlId
      }
    })
  );

/**
 * Debounce
 * @param {*} func
 * @param {*} wait
 */
export function debounce(func, wait) {
  let timeout;
  // eslint-disable-next-line
  return function (...args) {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(this, args);
    }, wait);
  };
}

/**
 * Generate a file url of a single pdf containing the sale label
 * laid out one label per page a multiple number of times
 *
 * @param {string} pdfUrl, the url string of the label image
 * @param {int} quantity, the number of times the label will be laid out on the pdf (no. of pages)
 */
export const createMultipleLabelsPDF = async (pdfURL, quantity) => {
  const srcPdfBytes = await fetch(pdfURL).then((res) => res.arrayBuffer());
  const { PDFDocument } = await import('pdf-lib');
  const srcPdfDoc = await PDFDocument.load(srcPdfBytes);
  const pdfDoc = await PDFDocument.create();
  const [srcPage] = await pdfDoc.copyPages(srcPdfDoc, [0]);
  let i;
  for (i = 1; i <= quantity; i += 1) {
    pdfDoc.addPage(srcPage);
  }
  const pdfBytes = await pdfDoc.save();
  const file = new File([pdfBytes], { type: 'application/pdf' });
  return URL.createObjectURL(file);
};

/**
 * Returns total boxes, kgs, and price from an array of sales
 * e.g. { boxes: 123, kg: 355, price: 2345.12 }
 *
 * @param sales the array of sales objects
 * @return object the object with the totals
 */
export const getTotalsFromSales = (sales) => {
  const initialValues = {
    boxes: 0,
    kg: 0,
    finalPrice: 0,
    initialPrice: 0
  };

  if (sales.length === 1) {
    return {
      boxes: sales[0].quantity,
      kg: sales[0].totalKg,
      finalPrice: sales[0].finalPrice,
      initialPrice: sales[0].initialPrice
    };
  }

  const getTotals = (totals, sale) => ({
    boxes: totals.boxes + sale.quantity,
    kg: totals.kg + sale.totalKg,
    finalPrice: totals.finalPrice + Number.parseFloat(sale.totalPrice),
    initialPrice: totals.initialPrice + sale.initialPrice * sale.totalKg
  });

  return sales.reduce(getTotals, initialValues);
};

/**
 * call back function passed to array reduce
 * to get the open list grouping of floating summary modal
 *
 * @param {*} acc accumulator
 * @param {*} item an elemen in the array
 */
export const formatOpenList = (acc, item) => {
  const groups = acc;
  if (!item) return groups;
  const date = dayjs(item.deliveryDate).format('YYYY-MM-DD');
  groups[date] = groups[date] || [];
  return groups;
};

/**
 * Gets a value un kgs and round it to two decimals. If bigger than 1k, it rounds to tons.
 * @param {*} value Value to round up
 */
export const getRoundedKgValue = (value) => {
  if (value >= 1000) {
    return roundTo2dp(value / 1000);
  }
  return Math.round(value);
};

export const getRoundedKgUnit = (value) => (value >= 1000 ? 't' : 'kg');

/*
 * Gets a pallet label from a logistic info id
 */
export const getPalletFromLogisticInfo = (logisticInfo = {}) => {
  if (logisticInfo) {
    return logisticInfo.preparedBy
      ? logisticInfo.preparedBy.company && logisticInfo.preparedBy.company.name
      : logisticInfo.arrivalPlatform &&
          logisticInfo.arrivalPlatform.alias &&
          logisticInfo.arrivalPlatform.alias.toUpperCase().split(' ')[0];
  }

  return '';
};

/**
 * Pluck fiels used for Mixpanel segmentation for a given Product
 * @param {*} productId number
 * @returns String
 */
export const formatProductForTracking = (product) => ({
  productId: product?.id,
  species: product?.species?.trackingKey,
  type: product?.type?.trackingKey,
  variation: product?.variation?.trackingKey,
  grade: product?.grade?.label,
  method: product?.fishingMethod?.trackingKey,
  areaCode: product?.fishingArea?.code ?? '',
  country: product?.harvestCountry ?? product?.country
});

/**
 * Pluck fiels used for Mixpanel segmentation for a given offer
 */
export const formatOfferForTracking = (offer) => ({
  offerId: offer.id,
  quantity: offer.quantity,
  packing: offer.packing && offer.packing.label,
  sellingPrice: offer.sellingPrice,
  negotiable: !!offer.negotiable,
  supplierPerformanceScore: offer.supplierPerformanceScore,
  isMustGo: offer.mustGo,
  isOpportunity: offer.opportunity,
  ...formatProductForTracking(offer.product)
});

/**
 * Pluck fiels used for Mixpanel segmentation for a given Sale
 * @param {*} productId number
 * @returns String
 */
export const formatSaleForTracking = (sale) => ({
  offerId: sale.offer.id,
  productId: sale.offer.product.id,
  quantity: sale.quantity,
  initialPrice: sale.initialPrice,
  finalPrice: sale.finalPrice,
  totalPrice: sale.totalPrice,
  totalKg: sale.totalKg,
  labelPrinted: sale.labelPrinted,
  buyerCompany: sale.buyerCompany,
  ...formatProductForTracking(sale.offer.product)
});

/**
 * Attach correct unit to price number and formats it
 * @param {float} price
 * @param {string} currency
 * @returns {string}
 */
export const formatPrice = (price, currency) => {
  const formatter = new Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency: currency ?? 'EUR'
  });

  return formatter.format(price ?? 0);
};

/**
 * Gets the formatted price string for a given offer and buyer. If no price provided, falls back to the customer price
 * of the offer.
 *
 * @param offer The offer that you want to format the price string for
 * @param company The buyer that you want to get the price for
 * @param buyerPrice An optional argument for the price to be formatted. Calculates customer price otherwise.
 * @returns {string}
 */
export const getFormattedPriceForOffer = (offer, company = null, buyerPrice = null) => {
  const price = buyerPrice !== null ? buyerPrice : Rooser.calculateCustomerPrice(offer, company);

  const unit = offer && offer.packing && offer.packing.unit;
  const currency = company ? company.currency : 'EUR';

  const finalPrice = WeightLogic.getPriceToDisplay(price, offer);

  const formattedPrice = formatPrice(finalPrice, currency);

  const unitString = unit ? ` /${WeightLogic.getPricedUnitSymbol(unit)}` : '';

  return `${formattedPrice}${unitString}`;
};

/**
 * Gets a summry string for the given product containing:
 *  - the species name
 *  - the type label
 *  - the variation label
 *  - the grade label
 *
 * @param {object} product
 * @returns {string}
 */
export const getProductSummary = (product) => {
  const productDetails = [];

  if (product?.species?.name) {
    productDetails.push(product.species.name);
  }

  if (product?.type?.label) {
    productDetails.push(product.type.label);
  }

  if (product?.variation?.label) {
    productDetails.push(product.variation.label);
  }

  if (product?.grade?.label) {
    productDetails.push(product.grade.label);
  }

  return productDetails.join(' ');
};

/**
 * Gets a buyer summary string for the given price request.
 *
 * @param {object} priceRequest
 * @param {number} userCompanyType
 * @returns {string}
 */
export const getPriceRequestSummary = (priceRequest, userCompanyType) => {
  let priceRequestSummary = '';
  if (priceRequest?.offer) {
    const {
      offer: { product, packing },
      requestedBuyerPrice,
      requestedSupplierPrice,
      buyerCompany,
      quantityRequested
    } = priceRequest;

    if (product) {
      priceRequestSummary += `${getProductSummary(product)} - `;
    }

    priceRequestSummary += quantityRequested;

    if (packing) {
      priceRequestSummary += `x${packing.label}`;
    }

    const formattedPrice = getFormattedPriceForOffer(
      priceRequest.offer,
      buyerCompany,
      userCompanyType === companyType.buyer ? requestedBuyerPrice : requestedSupplierPrice
    );

    priceRequestSummary += ` @ ${formattedPrice}`;
  }

  return priceRequestSummary;
};

export const getProductString = (
  product,
  showCertificates = false
) => {
  const speciesString =
    isSkreiCod(product) && !showCertificates
      ? `${product?.species?.name} ${SKREI}`
      : product?.species?.name;

  const brandingString =
    product?.brandings?.length > 0 ? ` "${product?.brandings?.map(({ name }) => name).join(', ')}"` : '';

  const certificatesString =
    product?.certificates?.length > 0 && showCertificates
      ? ` ${product?.certificates?.map(({ shortName }) => shortName).join(', ')}`
      : '';

  const typeString = ` ${product?.type?.label}`;

  const transportMethodString =
    product?.transportMethod?.name &&
    !product?.transportMethod?.isDefault ? ` ${product?.transportMethod?.name}` : '';

  return `${speciesString}${certificatesString}${brandingString}${typeString}${transportMethodString}`;
};

export const groupOffersBySpeciesAndType = (offers = []) =>
  [...offers].sort(sortOffers).reduce((groupedOffers, offer) => {
    if (!offer.product?.species || !offer.product?.type) {
      return groupedOffers;
    }

    const productString = getProductString(offer.product);
    const group = { ...groupedOffers };
    group[productString] = group[productString] ?? [];
    group[productString].push(offer);
    return group;
  }, {});

export const groupProductsBySpeciesAndType = (products = []) =>
  [...products].sort(sortProducts).reduce((groupedProducts, product) => {
    if (!product?.species || !product?.type) {
      return groupedProducts;
    }

    const productString = getProductString(product);
    const group = { ...groupedProducts };
    group[productString] = group[productString] || [];
    group[productString].push(product);
    return group;
  }, {});

/**
 * Opens a new tab in the browser (works across all browsers).
 *
 * @param {string|null} url
 */
export const openNewTab = (url) => {
  const a = document.createElement('a');
  a.style = 'display: none';
  a.href = url;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
