import Analytics from 'core/analytics';
import * as ActionTypes from './actionTypes';

/**
 * Show toast on the screen
 */
export const toast =
  ({
    type = 'neutral',
    message = '',
    action = undefined,
    payload = undefined,
    actionLabel = undefined,
    icon = undefined
  }) =>
  (dispatch) => {
    dispatch({
      type: ActionTypes.SET_TOAST,
      payload: {
        toastType: type,
        toastMessage: message,
        toastClickAction: action,
        toastPayload: payload,
        toastActionLabel: actionLabel,
        toastIcon: icon
      }
    });
    dispatch({ type: ActionTypes.SHOW_TOAST });
    Analytics.track('toast_displayed_app', { title: message, type });
  };

export const hideToast = () => (dispatch, getState) => {
  const { toastType, toastMessage } = getState().modal;
  dispatch({ type: ActionTypes.HIDE_TOAST });
  Analytics.track('toast_dismissed_app', { title: toastMessage, type: toastType });
};

export const autoHideToast = (title, type) => (dispatch) => {
  dispatch({ type: ActionTypes.HIDE_TOAST });
  Analytics.track('toast_auto_dismissed_app', { title, type });
};

export const errorToast = (err) => (dispatch) => {
  dispatch(toast({ type: 'error', message: err, icon: 'circle-xmark' }));
};
